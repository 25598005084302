/* Base **********************/
/* Fonts *****************************/
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
/* Font Weights **********************/
/* Z-Index **********************/
/* Main Color Scheme **********************/
/* Grays */
/* Highlight Colors */
/* Utilities */
@media (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: block;
  }
}

@media (min-width: 768px) {
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
}

/*
USAGE:
  @include breakpoint(sm) {
    //content
  }

*/
/* OLD VERSION Media Queries Mixin**********************/
/*
Project: ET
Authors: Wendy Vargas, Wilmer Murillo, Fernando Guevara
Description: CSS and SASS code for all the modules of the ET Project
Version: 1.0
**********************/
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #4d73ff;
  font-weight: 500;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a,
a:hover,
a:visited,
a:link,
a:active {
  outline: none;
}

a {
  outline: none;
}

a.disabled {
  color: gray;
  cursor: not-allowed;
}

a[disabled] {
  pointer-events: none;
  display: inline-block;
  color: gray;
  cursor: not-allowed;
}

.bodywrap {
  margin-top: 62px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
}

.fullheight {
  height: 100%;
}

/* Font & heading styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1 {
  color: black;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0px;
}

.h2-var {
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0px;
  font-style: italic;
  color: black;
}

h2 {
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0px;
  color: black;
  font-weight: 400;
}

h4,
h5,
h6 {
  color: black;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0px;
}

h3 {
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0px;
  color: black;
  font-weight: 400;
}

.h3-var {
  color: black;
  text-transform: none;
}

h4,
h5,
h6 {
  color: black;
}

p {
  margin: 10px 0;
  text-rendering: optimizeLegibility;
}

.bg-main-color,
span.status.status-main-et-color,
.status-main-et-color.status-ball--small {
  background-color: #4d73ff;
}

.bg-blue,
span.status.status-et-blue,
.status-et-blue.status-ball--small {
  background-color: #30a5c3;
}

.bg-green,
span.status.status-et-green,
.status-et-green.status-ball--small {
  background-color: #4cbb17;
}

.bg-orange,
span.status.status-et-orange,
.status-et-orange.status-ball--small {
  background-color: #7734e7;
}

.bg-red,
span.status.status-et-red,
.status-et-red.status-ball--small {
  background-color: #d70000;
}

.bg-purple,
span.status.status-et-purple,
.status-et-purple.status-ball--small {
  background-color: #7734e7;
}

/* Ligher color background*/
.bg-lighter-blue,
span.status.status-lighter-blue,
.status-lighter-blue.status-ball--small,
span.status.status-lighter-orange,
.status-lighter-orange.status-ball--small {
  background-color: #98beff;
}

.bg-lighter-green,
span.status.status-lighter-green,
.status-lighter-green.status-ball--small {
  background-color: #96f066;
}

.bg-lighter-orange,
span.status.status-lighter-red,
.status-lighter-red.status-ball--small {
  background-color: #c5b7fb;
}

.bg-lighter-red {
  background-color: #ff5757;
}

.bg-lighter-purple,
span.status.status-lighter-purple,
.status-lighter-purple.status-ball--small {
  background-color: #c5b7fb;
}

.bg-lightest-blue,
span.status.status-lightest-blue,
.status-lightest-blue.status-ball--small {
  background-color: #ebf5ff;
}

/* Darker color background*/
.bg-darker-blue,
span.status.status-darker-blue,
.status-darker-blue.status-ball--small {
  background-color: #2136e1;
}

.bg-darker-green,
span.status.status-darker-green,
.status-darker-green.status-ball--small {
  background-color: #296113;
}

.bg-darker-orange,
span.status.status-darker-orange,
.status-darker-orange.status-ball--small {
  background-color: #5d25b2;
}

.bg-darker-red,
span.status.status-darker-red,
.status-darker-red.status-ball--small {
  background-color: #b10303;
}

.bg-darker-purple,
span.status.status-darker-purple,
.status-darker-purple.status-ball--small {
  background-color: #4e2092;
}

/* Grays */
.bg-dark-gray,
span.status.status-dark-gray,
.status-dark-gray.status-ball--small {
  background-color: #6a6a6a;
}

.bg-gray,
span.status.status-gray,
.status-gray.status-ball--small {
  background-color: #9a9a9a;
}

.bg-midlight-gray,
span.status.status-midlight-gray,
.status-midlight-gray.status-ball--small {
  background-color: #b4b4b4;
}

.bg-light-gray,
span.status.status-light-gray,
.status-light-gray.status-ball--small {
  background-color: #b4b4b4;
}

.bg-lighter-gray,
span.status.status-lighter-gray,
.status-lighter-gray.status-ball--small {
  background-color: #d1d1d1;
}

.bg-mist,
span.status.status-mist,
.status-mist.status-ball--small {
  background-color: #f8f8f8;
}

/* Highlight Colors */
.bg-highlight-blue,
span.status.status-highlight-blue,
.status-highlight-blue.status-ball--small {
  background-color: #ebf5ff;
}

.bg-highlight-green,
span.status.status-highlight-green,
.status-highlight-green.status-ball--small {
  background-color: #f1fee7;
}

.bg-highlight-orange,
span.status.status-highlight-orange,
.status-highlight-orange.status-ball--small {
  background-color: #f5f3ff;
}

.bg-highlight-red,
span.status.status-highlight-red,
.status-highlight-red.status-ball--small {
  background-color: #fff0f0;
}

.bg-highlight-purple,
span.status.status-highlight-purple,
.status-highlight-purple.status-ball--small {
  background-color: #f5f3ff;
}

.ft-connect-with-fb {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  width: 214px;
  height: 50px;
  background: #1877f2;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0898154);
  border-radius: 4px;
  margin-left: 10px;
}

/* Modules **********************/
.column-one,
.column-two {
  margin-top: 1%;
  padding: 0 1%;
}

.col-centered {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.panel .panel-head {
  border-bottom: solid 1px #e4e4e4;
  margin-bottom: 16px;
  padding: 1.8% 1%;
}
.panel .panel-head h2,
.panel .panel-head h3,
.panel .panel-head h4 {
  margin: 0;
}

.panel.unit-information .panel-head,
.panel.unit-charges .panel-head,
.panel.task-summary .panel-head,
.panel.panel--widget .panel-head {
  padding: 15px 20px;
}

.panel.unit-information h2,
.panel.unit-charges h2,
.panel.task-summary h2,
.panel.panel--widget h2 {
  font-weight: 500;
}

.panel.block-white-shadow {
  border: 0;
}

.panel p {
  word-wrap: break-word;
}

.panel-title {
  font-size: unset;
}

.block-white-shadow {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
}

.block-gray {
  background-color: #f5f5f5;
  box-shadow: 0;
}

.people-profile-left {
  /*padding: 0 2%;*/
}
@media (min-width: 992px) {
  .people-profile-left {
    width: 38%;
    max-width: 445px;
  }
}
.people-profile-left .block-household .panel-footer {
  margin-top: 10px;
  background-color: #fff;
}
.people-profile-left .block-household .panel-footer i.icon {
  margin-right: 6px;
}
.people-profile-left .block-household .table-vehicles-preview {
  margin-bottom: 0;
  font-size: 1em;
}
.people-profile-left .panel-success,
.people-profile-left .panel-primary,
.people-profile-left .panel-default {
  background-color: #fff;
  border-color: transparent;
}
.people-profile-left .panel-success .panel-heading,
.people-profile-left .panel-primary .panel-heading,
.people-profile-left .panel-default .panel-heading {
  background-color: #fff;
  border-color: transparent;
}

@media (min-width: 992px) {
  .people-profile-right {
    width: calc(100% - 38%);
  }
}

@media (min-width: 1200px) {
  .people-profile-right {
    width: calc(100% - 445px);
  }
}

.resident-unit-container .icon {
  font-size: 0.99em;
  opacity: 0.8;
  margin-right: 5px;
}

.block h2 {
  display: inline-block;
  vertical-align: middle;
}

.block .panel-heading {
  padding-top: 4%;
  color: #4e4e4e;
}

.block .panel-heading h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.block .panel-body {
  padding-top: 0;
}
.block .panel-body .box-last-edit {
  margin: 0px 0 25px 0;
  padding: 13px 10px;
}

.block .box-prospect-notes {
  clear: both;
  margin: 8px 0;
  min-height: 45px;
  overflow: auto;
}
.block .box-prospect-notes textarea {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #ccc;
  height: 75px;
  padding: 5px;
}
.block .box-prospect-notes span.noteslabel {
  display: inline-block;
  float: left;
  width: 75px;
  font-weight: 500;
}
.block .box-prospect-notes span.notes-cont {
  display: inline-block;
  float: right;
  width: calc(100% - 75px);
}

.profile-details {
  font-weight: 400;
}

.panel-heading i {
  display: inline-block;
  margin-right: 2%;
}

.people-profile-left .panel-heading i {
  font-size: 24px;
}

.details-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 20px;
}
.details-list .assign-btns {
  display: inline-block;
  float: right;
}
.details-list li,
.details-list label {
  margin: 7px 0;
  font-size: 14px;
}
.details-list span,
.details-list label {
  font-weight: 500;
}

.block-profile .simple-link {
  display: block;
}
@media (min-width: 992px) {
  .block-profile .simple-link {
    margin-top: 10px;
    text-align: right;
  }
}

.people-profile-left .simple-link i {
  font-size: 100%;
}

/*
.alert {
	text-align: left;
	padding: 10px;
	border: solid 1px;
}

*/
.alert-primary {
  border-color: #2136e1;
  background-color: #ebf5ff;
  color: #2136e1;
}

.alert-secondary {
  border-color: #ff5757;
  background-color: #fff0f0;
  color: #b10303;
}

.alert-success {
  border-color: #96f066;
  background-color: #f1fee7;
  color: #296113;
}

.alert-success a {
  color: #296113;
}

.people-profile-right,
.people-profile-left {
  margin-top: 30px;
}
.people-profile-right h2,
.people-profile-left h2 {
  margin-bottom: 10px;
}
.people-profile-right span.lock-symbol,
.people-profile-left span.lock-symbol {
  background-color: #d70000;
  font-size: 12px;
  color: #fff;
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 4px;
  border-radius: 100px;
  margin-left: 10px;
}
.people-profile-right span.lock-symbol i,
.people-profile-left span.lock-symbol i {
  font-size: 14px;
}

.snapshot-actions,
.box-save-note {
  width: 90%;
  margin: 0 5%;
  text-align: center;
}
.snapshot-actions .btn-tertiary,
.snapshot-actions .btn-callto,
.box-save-note .btn-tertiary,
.box-save-note .btn-callto {
  margin-right: 2%;
}

.box-calendar {
  min-height: 400px;
  height: auto;
  margin-top: 20px;
  overflow: auto;
}

.dashboard-content .tab-pane,
.dashboard-content .tab-content {
  padding: 0;
}

.highlight-details.highlight-details--nobg {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-weight: 500;
}

.header-with-filters {
  margin-bottom: 15px;
}

.block-data {
  height: auto;
  margin-top: 10px;
  padding: 25px 6px 15px;
  text-align: center;
  font-weight: 500;
}
.block-data h5 {
  color: black;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}
.block-data h4 {
  margin-bottom: 12px;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
}
.block-data span {
  display: block;
}
@media (min-width: 992px) {
  .block-data span {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .block-data span {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .block-data {
    min-height: 110px;
    margin-top: 0;
  }
}

.disp-inline {
  display: inline-block;
  vertical-align: middle;
}

.block-data.block-white-shadow {
  padding-right: 12px;
  padding-left: 12px;
  min-height: 116px;
}

.block-data.block-gray {
  padding-top: 15px;
}

.block-data-inner {
  width: 100%;
  display: inline-block;
}
@media (min-width: 1200px) {
  .block-data-inner {
    width: 80%;
  }
}
.block-data-inner span {
  display: block;
}
@media (min-width: 992px) {
  .block-data-inner span {
    float: left;
    text-align: left;
  }
}

@media (min-width: 992px) {
  span.block-data-inner__amount {
    float: right;
  }
}

.card {
  background-color: #fff;
  box-shadow: 1px 1px 7px 0px rgba(50, 50, 50, 0.28);
  padding: 15px;
  width: 100%;
  margin-bottom: 20px;
}

/* Checklist small */
.checklist-small {
  background-color: #fff;
  padding: 10px 14px;
  margin: 0px 50px 40px 0px;
  box-shadow: 3px 3px 17px -6px rgba(0, 0, 0, 0.43);
}
.checklist-small ul {
  width: 100%;
  list-style: none;
  padding: 2px 0;
  margin-bottom: 0;
}
.checklist-small ul li {
  border-bottom: dotted 1px #ccc;
  height: auto;
  padding: 5px 0;
  min-height: 35px;
}
.checklist-small ul li .item-property {
  width: 70%;
  font-weight: 500;
  height: auto;
  display: inline-block;
}
.checklist-small ul li .item-value {
  width: 29%;
  float: right;
  text-align: right;
}
.checklist-small ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.checklist-small span.status,
.checklist-small .status-ball--small {
  margin-left: 10px;
}

.panel.panel-has-table .table > tbody > tr > td {
  border: 0;
}

.panel__details {
  padding-right: 2%;
  padding-left: 2%;
}
.panel__details .row {
  padding: 5px 0;
}

.panelwlargeheader {
  padding-top: 10px;
}

.divider-centered--larger {
  width: 90%;
  margin: 10px auto 0 auto;
  height: 1px;
  background: #f4eaea;
  clear: both;
}

.divider-vertical {
  border-left: 1px solid #f4eaea;
}

.block-with-padding {
  padding: 15px;
}

.highlighted-footer,
.highlighted-footer--red,
.highlighted-footer--green {
  padding: 10px;
  color: #4d73ff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  background-color: #ebf5ff;
}

.highlighted-footer--red {
  color: #d70000;
  background-color: #fff0f0;
}

.highlighted-footer--green {
  color: #4cbb17;
  background-color: #f1fee7;
}

.row-active {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f5f5f5;
}
.row-active .form-group {
  margin-bottom: 0;
}

.faux-table__row {
  padding: 6px 0;
}
.faux-table__row span {
  white-space: pre-line;
}
.faux-table__row .form-group {
  margin-bottom: 6px;
}

.faux-table .faux-table__row:nth-child(even) {
  background-color: #f8f8f8;
}

.accordion-applicationmanager .panel {
  box-shadow: none;
}

.box-with-border {
  border: 1px solid #b4b4b4;
}
.box-with-border .form-group {
  margin-bottom: 0;
}
.box-with-border .custom-checkbox {
  margin: 0;
}

.box-with-border__row,
.box-with-border__head {
  padding: 8px 8px 3px;
}

/* classes to make btn-block device-dependent */
.btn {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 160px;
  min-height: 40px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.btn .icon,
a .icon {
  margin: 0 4% 0 0;
}

button:focus {
  outline: 0;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #ffffff;
}

.btn.btn-text:hover,
.btn.btn-text:focus,
.btn.btn-text.focus,
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link.focus {
  color: #2136e1;
}

input[type='submit'],
input[type='button'],
a.button {
  background-color: #4d73ff;
  font-size: 14px;
  color: #fff;
  border-radius: 6px;
  border: 0;
  padding: 10px 15px;
  min-width: 200px;
}

.btn-primary.btn-default:hover {
  color: #ffffff;
}

.btn-default:hover {
  color: #6a6a6a;
}

.btn.extended {
  width: 100%;
}

.btn-tertiary,
.btn.btn-tertiary {
  color: #4d73ff;
  background: white;
  border: 1px solid #4d73ff;
  border-radius: 5px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-tertiary,
  .btn.btn-tertiary {
    min-width: auto;
  }
}
.btn-tertiary:hover,
.btn-tertiary:focus,
.btn.btn-tertiary:hover,
.btn.btn-tertiary:focus {
  color: white;
  background: #98beff;
}

.table .btn.btn-sm.btn-tertiary {
  min-height: auto;
  min-width: 1px;
  width: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

.btn-callto {
  color: #5d25b2;
  background: white;
  border: 1px solid #5d25b2;
  border-radius: 5px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-callto {
    min-height: 40px;
    min-width: auto;
  }
}
.btn-callto:hover,
.btn-callto:focus,
.btn-callto:active {
  background-color: #5d25b2;
}

.btn-action {
  color: #fff;
  background: #96f066;
  border: 1px solid #96f066;
  border-radius: 5px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-action {
    min-height: 40px;
    min-width: auto;
  }
}
.btn-action:hover {
  background-color: #296113;
}

.btn-shout,
.btn-shout.btn-default {
  color: #fff;
  background: #7734e7;
  border: 1px solid transparent;
  border-radius: 4px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-shout,
  .btn-shout.btn-default {
    min-height: 40px;
    min-width: auto;
  }
}
.btn-shout:focus,
.btn-shout:active,
.btn-shout:hover,
.btn-shout.btn-default:focus,
.btn-shout.btn-default:active,
.btn-shout.btn-default:hover {
  background-color: #5d25b2;
  color: #fff;
}

.btn.disabled,
.btn[disabled],
button[disabled]:not(.Mui-disabled):not(.MuiTypography-root),
.btn.btn-tertiary[disabled],
.btn.btn-shout[disabled],
.btn.btn-primary[disabled] {
  background-color: #9a9a9a;
  color: #ffffff;
}
.btn.disabled:hover,
.btn[disabled]:hover,
button[disabled]:hover:not(.MuiTypography-root),
.btn.btn-tertiary[disabled]:hover,
.btn.btn-shout[disabled]:hover,
.btn.btn-primary[disabled]:hover {
  background-color: #6a6a6a;
}

.btn-comments,
tbody td .btn.btn-sm.btn-comments,
tbody td .btn.btn-sm.btn-default.btn-comments {
  color: #7734e7;
  border: solid 1px #7734e7;
}

.btn-primary--ghost {
  background: white;
  color: #4d73ff;
  border: 1px solid #4d73ff;
  border-radius: 5px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-primary--ghost {
    min-height: 1px;
  }
}

.btn-primary--ghost:hover {
  color: #fff;
  background-color: #98beff;
}

.btn-primary--ghost-white {
  background: white;
  color: #525252;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  text-decoration: none;
}
@media only screen and (min-width: 992px) {
  .btn-primary--ghost-white {
    min-height: 40px;
    min-width: auto;
  }
}
.btn-primary--ghost-white:hover,
.btn-primary--ghost-white:focus,
.btn-primary--ghost-white:active {
  background-color: #4d73ff;
}

.btn-group .active {
  background-color: #4d73ff;
  color: white;
}

.btn-text {
  width: auto;
  min-width: 1px;
  font-weight: 500;
  display: inline-block;
  padding: 10px 0;
}
.btn-text i.icon {
  margin: 0 4px;
}

.btn.btn-text--small {
  min-height: 30px;
  min-width: 1px;
  width: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.btn.btn-text:hover,
.btn-text:hover {
  color: #2136e1;
}

table .btn.btn-text--small {
  color: #4d73ff;
  font-size: 1em;
}

.btn-text--green {
  color: #4cbb17;
}
.btn-text--green i {
  font-size: 20px;
  vertical-align: top;
  font-weight: 500;
}

.btn-text--red {
  color: #d70000;
}
.btn-text--red i {
  font-size: 18px;
  vertical-align: top;
  font-weight: 500;
}

.btn-text.icon-bigger i.icon {
  font-size: 1.1em;
  position: relative;
  top: 2px;
}

.btn-text.disabled,
.btn.btn-link.btn-tiny.disabled,
.btn-text--small[disabled] {
  color: #9a9a9a;
  background: transparent;
}
.btn-text.disabled:hover,
.btn.btn-link.btn-tiny.disabled:hover,
.btn-text--small[disabled]:hover {
  color: #9a9a9a;
  background-color: transparent;
  cursor: not-allowed;
}

/* Filter button */
.selected-filter {
  background-color: #4d73ff;
}

.button-filter {
  height: 40px;
  width: auto;
  padding-top: 7px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 2px;
  box-shadow: none;
  font-size: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
}

.filters-are-active,
.button-filter:hover {
  color: #ffffff;
  background-color: #4d73ff;
}

.filters-are-active:hover {
  color: white;
  background-color: #2136e1;
}

/* for manage screens*/
.btn--section-header {
  margin: 0 4px;
}

/* button in table header and td */
.dropdown button.btn-sm,
.dropdown-is-button {
  width: auto;
  background: transparent;
  border: 0;
  color: white;
  margin: 0;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  display: inline-block;
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
  background: transparent;
}

.dropdown-toggle.btn {
  min-width: 1px;
  min-height: 1px;
}

tbody .dropdown button.btn-sm,
tbody .dropdown-is-button {
  width: 100%;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  color: #4d73ff;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  display: inline-block;
}

@media (min-width: 776px) and (max-width: 1024px) {
  .dropdown button.btn-sm {
    font-size: 12px;
  }
}

.dropdown button.btn-sm .caret {
  margin-left: 3%;
}

.dropdown button.btn-default:hover,
.dropdown button.btn-default:focus {
  background: transparent;
}

.dropdown button.btn-default:active {
  background: transparent;
  box-shadow: none;
}

.btn-primary-outline {
  padding: 10px 15px;
  min-width: 200px;
  display: inline-block;
}

.alert .btn-default {
  margin-right: 10px;
}

.btn-delete {
  color: #ffffff;
  border: 1px solid #d70000;
  background-color: #d70000;
}
.btn-delete i,
.btn-delete span {
  color: #ffffff;
}
.btn-delete:hover {
  background-color: #ff5757;
  border-color: #ff5757;
}

.btn-delete-alt {
  color: #d70000;
  border: 1px solid #d70000;
  background-color: none;
}
.btn-delete-alt i,
.btn-delete-alt span {
  color: #d70000;
}
.btn-delete-alt:hover {
  background-color: #ff5757;
  border-color: #ff5757;
  color: #ffffff;
}
.btn-delete-alt:hover i {
  color: #ffffff;
}

.btn-tertiary.btn-delete,
.modal-lg .modal-footer .btn.btn-tertiary.btn-delete {
  color: #4d73ff;
  background-color: #ffffff;
  border: 1px solid #4d73ff;
}
@media only screen and (min-width: 768px) {
  .btn-tertiary.btn-delete,
  .modal-lg .modal-footer .btn.btn-tertiary.btn-delete {
    min-width: 1px;
    text-align: center;
  }
}
.btn-tertiary.btn-delete i,
.modal-lg .modal-footer .btn.btn-tertiary.btn-delete i {
  color: #4d73ff;
}
.btn-tertiary.btn-delete:hover,
.modal-lg .modal-footer .btn.btn-tertiary.btn-delete:hover {
  color: #ffffff;
  border: 1px solid #d70000;
  background-color: #d70000;
}
.btn-tertiary.btn-delete:hover i,
.btn-tertiary.btn-delete:hover span,
.modal-lg .modal-footer .btn.btn-tertiary.btn-delete:hover i,
.modal-lg .modal-footer .btn.btn-tertiary.btn-delete:hover span {
  color: #ffffff;
}

td .btn {
  margin: 0;
}

td .dropdown .btn-group .btn-group-sm,
td .btn .btn-sm .btn-default {
  width: 100%;
  min-height: 30px;
}
td .dropdown .btn-group .btn-group-sm .btn-default,
td .btn .btn-sm .btn-default .btn-default {
  color: #2b90ab;
  background-color: transparent;
  border-color: #2b90ab;
}

td .btn .btn-sm .btn-default {
  color: #2b90ab;
  background-color: transparent;
  border-color: #2b90ab;
}

tbody td .btn.btn-sm.btn-default {
  min-width: auto;
  height: 27px;
  min-height: 1px;
  width: 38px;
  padding: 5px 12px;
  display: inline-block;
  margin: 0 auto;
  float: none;
  color: #4d73ff;
  border-color: #4d73ff;
  font-size: 1.1em;
}

tbody .btn-group-sm button.dropdown-toggle.btn-default {
  color: #4d73ff;
  border-color: #4d73ff;
  padding: 5px;
}

.dropdown-toggle.btn.btn-primary {
  height: 40px;
}

@media (min-width: 768px) {
  .section-header .btn {
    margin-top: 0;
    float: right;
  }
}

/* Switch Buttons */
.btn-switch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-switch-checkbox {
  display: none;
}

.btn-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #9a9a9a;
  border-radius: 20px;
}

.btn-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.btn-switch-inner:before,
.btn-switch-on-off-inner:before,
.btn-switch-inner:after,
.btn-switch-on-off-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  box-sizing: border-box;
}

.btn-switch-inner:before,
.btn-switch-on-off-inner:before {
  content: 'Yes';
  padding-left: 10px;
  background-color: #ffffff;
  color: #98beff;
  position: relative;
  left: -13px;
  font-weight: 400;
  text-align: center;
}

.btn-switch-inner:after,
.btn-switch-on-off-inner:after {
  content: 'No';
  padding-right: 10px;
  background-color: #ffffff;
  color: #b4b4b4;
  text-align: right;
  font-weight: 400;
  position: relative;
  left: -8px;
}

.btn-switch-on-off-inner:before {
  content: 'On';
}

.btn-switch-on-off-inner:after {
  content: 'Off';
}

.btn-switch-switch {
  display: block;
  width: 18px;
  margin: 5px;
  height: 18px;
  background: #a1a1a1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.btn-switch-checkbox:checked + .btn-switch-label .btn-switch-inner {
  margin-left: 0;
}

.btn-switch-checkbox:checked + .btn-switch-label .btn-switch-switch {
  right: 0px;
  background-color: #4d73ff;
}

.btn-switch-checkbox:checked ~ .btn-switch-label {
  border: 1px solid #4d73ff;
  border-radius: 20px;
}

.btn-switch.disabled .btn-switch-label {
  border: 1px solid #d1d1d1;
  cursor: not-allowed;
}

.btn-switch.disabled .btn-switch-switch {
  background: #d1d1d1;
}

.btn-switch-checkbox input[type='checkbox'][disabled] ~ label.btn-switch-label {
  border: 1px solid #d1d1d1;
  cursor: not-allowed;
}
.btn-switch-checkbox
  input[type='checkbox'][disabled]
  ~ label.btn-switch-label
  .btn-switch-switch {
  background: #d1d1d1;
}

input.btn-switch-checkbox[type='checkbox'][disabled] + label.btn-switch-label {
  border: 1px solid #d1d1d1;
  cursor: not-allowed;
}
input.btn-switch-checkbox[type='checkbox'][disabled]
  + label.btn-switch-label
  .btn-switch-switch {
  background: #d1d1d1;
}

.btn-switch.has-error .btn-switch-label {
  border: 1px solid #d70000;
  cursor: not-allowed;
}

.btn-switch.has-error .btn-switch-inner:after,
.btn-switch.has-error .btn-switch-on-off-inner:after {
  color: #d70000;
}

.btn-switch.has-error .btn-switch-switch {
  background: #d70000;
}

.btn-switch.warning .btn-switch-label {
  border: 1px solid #7734e7;
  cursor: not-allowed;
}

.btn-switch.warning .btn-switch-inner:after,
.btn-switch.warning .btn-switch-on-off-inner:after {
  color: #7734e7;
}

.btn-switch.warning .btn-switch-switch {
  background: #7734e7;
}

.error-switch {
  width: 100%;
  text-align: right;
  display: inline-block;
}

.btn-tiny,
.panel-heading .btn-tiny i {
  min-width: 1px;
  width: auto;
  min-height: 1px;
  height: auto;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1em;
}

.panel-button .et-plus,
.panel-button .et-minus {
  font-size: 25px;
}

button#split-button-basic + ul.dropdown-menu {
  right: auto;
  left: 0;
}

.btn i.text-red:hover {
  color: #ffffff;
}

/* Colors for status */
span.status,
.status-ball--small {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: top;
  margin: 4px auto 0;
  background-color: gray;
  border-radius: 50em;
}

span.status.status-orange,
.status-orange.status-ball--small {
  background-color: #7734e7;
}

span.status.status-dark-orange,
.status-dark-orange.status-ball--small {
  background-color: #cf5813;
}

span.status.status-purple,
.status-purple.status-ball--small {
  background-color: #6851ea;
}

span.status.status-dark-purple,
.status-dark-purple.status-ball--small {
  background-color: #4a29cb;
}

span.status.status-white,
.status-white.status-ball--small {
  background-color: white;
}

span.status.status-red,
.status-red.status-ball--small {
  background-color: #ff5757;
}

span.status.status-blue,
.status-blue.status-ball--small {
  background-color: #639aee;
}

span.status.status-green,
.status-green.status-ball--small {
  background-color: #4cbb17;
}

span.status.status-gray,
.status-gray.status-ball--small {
  background-color: darkgray;
}

span.status.status-lightgray,
.status-lightgray.status-ball--small {
  background-color: lightgray;
}

span.status.status-black,
.status-black.status-ball--small {
  background-color: #333;
}

/* Ligher color background*/
/* Darker color background*/
/* Grays */
/* Highlight Colors */
a.status-orange {
  color: orange;
}

.status-ball {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  display: inline-block;
}

.status-ball--small {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  margin: 8px auto 0;
  display: inline-block;
}

.dropdown-menu span.status,
.dropdown-menu .status-ball--small {
  margin: 4px 10px 0 0;
}

.scheduled-info--basic h4 {
  margin-right: 2%;
  margin-bottom: 0;
  display: inline-block;
  font-weight: 500;
}

.scheduled-info--basic h5 {
  margin-right: 2%;
  margin-bottom: 0;
  display: inline-block;
  font-weight: normal;
  font-size: 10px;
}

.scheduled-info--basic i {
  margin-right: 2%;
  vertical-align: middle;
}

.alert.alert-default {
  background-color: #f7f7f7;
  font-weight: 400;
  border: solid 1px #ccc;
}

.alert.alert-info {
  background-color: #f5f3ff;
  font-weight: 400;
  border: solid 1px #7734e7;
  color: #7734e7;
}

/*Divider */
/*.divider {
	width: 100%;
	height: 1px;
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 20px;
	background: #e4e4e4;
}*/
/* Helper Classes */
.divider,
.divider-dotted {
  width: 100%;
  height: 1px;
  margin: 2% 0;
  background: #f4eaea;
  clear: both;
}

.divider-centered {
  width: 70%;
  height: 1px;
  margin: 15px auto;
  background: #f4eaea;
  clear: both;
}

.divider-dotted {
  border-top: dotted 1px #b4b4b4;
}

.list-simple {
  list-style: none;
  margin: 0 0 24px 0;
  padding: 0;
}

/* Tags */
.status-tag {
  width: auto;
  height: 24px;
  min-width: 115px;
  display: inline-block;
  margin: 0 4px;
  padding: 2px;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #9a9a9a;
  font-size: 12px;
}

.status-tag--green {
  background-color: #4cbb17;
}

.status-tag--green-light {
  background-color: transparent;
  border: solid 1px #4cbb17;
  color: #4cbb17;
}

.status-tag--seagreen {
  background-color: #96f066;
}

.status-tag-blue {
  background-color: #4d73ff;
}

.status-tag-lightblue {
  background-color: #98beff;
}

.status-tag-purple {
  background-color: #7734e7;
}

.status-tag-orange {
  background-color: #7734e7;
}

.status-tag--orange-light {
  background-color: transparent;
  border: solid 1px #c5b7fb;
  color: #c5b7fb;
}

.status-tag-yellow {
  background-color: #f0a524;
}

.status-tag-red {
  background-color: #d70000;
}

.status-tag--red-light {
  background-color: transparent;
  border: solid 1px #ff5757;
  color: #ff5757;
}

.status-tag--red-dark {
  background-color: #b10303;
  border: solid 1px #b10303;
  color: #fff;
}

.status-tag--gray-light {
  background-color: transparent;
  border: solid 1px #b4b4b4;
  color: #b4b4b4;
}

.simple-link i,
.link-availability i {
  margin-right: 3px;
  font-size: 100%;
}

.link-availability {
  margin-top: 10px;
}

.list-btm-border {
  padding-left: 0;
  list-style-type: none;
}
.list-btm-border li {
  padding: 3% 0;
  border-bottom: 1px solid #b4b4b4;
}
.list-btm-border li:last-child {
  border-bottom: 0;
}

.list-no-border {
  padding-left: 0;
  list-style-type: none;
}
.list-no-border li {
  padding: 4px 0;
}
.list-no-border li:last-child {
  border-bottom: 0;
}

.no-padding-top {
  padding-top: 0px;
}

.box-last-edit {
  padding: 1px 20px;
  color: #98beff;
  background-color: #ebf5ff;
  border: 1px solid #98beff;
  font-size: 14px;
}

.loading-container {
  margin: 75px 0;
}
.loading-container h5 {
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
}

.spinner {
  height: 60px;
  width: 45px;
  margin: 10px auto;
}
.spinner .spinnerbar {
  width: 4px;
  height: 29px;
  background-color: #d8d8d8;
  float: left;
  margin-right: 5px;
  border-radius: 5px;
  animation: loadingpulse 1s infinite;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes loadingpulse {
  0% {
    height: 29px;
  }
  90% {
    height: 44px;
  }
  100% {
    height: 28px;
  }
}
.spinner .spnbar1 {
  animation-delay: 0.2s;
}
.spinner .spnbar2 {
  animation-delay: 0.4s;
}
.spinner .spnbar3 {
  animation-delay: 0.6s;
}
.spinner .spnbar4 {
  animation-delay: 0.8s;
}
.spinner .spnbar5 {
  animation-delay: 1s;
}

/* Inline Spinner */
.loading-container--inline {
  display: inline-block;
  margin: 0 15px;
  vertical-align: bottom;
}

/* Smaller Version */
.loading-container--small .spinner {
  height: 27px;
  margin: 0 auto;
}
.loading-container--small .spinner .spinnerbar {
  height: 20px;
  -webkit-animation: loadingpulsesmall 1s infinite;
  animation: loadingpulsesmall 1s infinite;
}

@keyframes loadingpulsesmall {
  0% {
    height: 12px;
  }
  90% {
    height: 26px;
  }
  100% {
    height: 10px;
  }
}
.loading-container--small .spinner .spnbar1 {
  animation-delay: 0.2s;
}
.loading-container--small .spinner .spnbar2 {
  animation-delay: 0.4s;
}
.loading-container--small .spinner .spnbar3 {
  animation-delay: 0.6s;
}
.loading-container--small .spinner .spnbar4 {
  animation-delay: 0.8s;
}
.loading-container--small .spinner .spnbar5 {
  animation-delay: 1s;
}

/* --- PAGE: Login --- */
.loginwrapper {
  background-color: #fff;
  width: 90%;
  margin: 20vh auto 0 auto;
  max-width: 500px;
}
.loginwrapper .login-container {
  padding: 5px 10% 45px 10%;
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.18);
}
.loginwrapper .login-container .blue-label {
  color: #4d73ff;
  text-transform: uppercase;
  font-weight: 400;
  font: 16px;
}
.blue-label {
  color: #4d73ff;
  text-transform: uppercase;
  font-weight: 400;
  font: 16px;
}
.loginwrapper input[type='submit'],
.loginwrapper button {
  margin: 0 auto;
  display: block;
}
.loginwrapper label {
  font-weight: 400;
}
.loginwrapper .login-details {
  font-size: 15px;
  margin-bottom: 20px;
}
.loginwrapper .login-details h4 {
  font-size: 18px;
  font-weight: 400;
}
.loginwrapper .btn-primary-outline {
  margin-top: 10px;
}
.loginwrapper .loginform small {
  display: block;
}
.loginwrapper .confirm-icon {
  color: #96f066;
  font-size: 2.7em;
  display: block;
  margin-bottom: 10px;
}

.password-validator.container-fluid {
  text-align: left;
  border: solid 1px #ccc;
  padding: 12px 8px;
  font-size: 14px;
}
.password-validator.container-fluid ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.password-validator.container-fluid ul.validation-lights {
  text-align: right;
}
.password-validator.container-fluid ul.validation-lights span {
  display: none;
}
.password-validator.container-fluid ul.validation-lights li:after {
  content: '';
  width: 12px;
  height: 12px;
  border: solid 1px #ccc;
  display: inline-block;
  border-radius: 100px;
}
.password-validator.container-fluid ul.validation-lights li.validated:after {
  background-color: #96f066;
}

.loginwrapper h1.epm-logo {
  width: 160px;
  height: 200px;
  margin: 0px auto;
}
.loginwrapper h1.epm-logo span {
  display: none;
}

.loginwrapper h1.fortress-logo-gray {
  width: 260px;
  height: 120px;
  margin: 0px auto;
}
.loginwrapper h1.fortress-logo-gray span {
  display: none;
}

.timeout-logo {
  width: 155px;
  height: 186px;
  margin: 0px auto auto;
}
.timeout-logo span {
  display: none;
}

.footer-login {
  padding: 50px;
  width: 500px;
  margin: auto;
  font-size: 0.9em;
}

.dashboard-topmenu .nav-tabs {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  z-index: 2;
  max-width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #d1d7d8;
}

.dashboard-topmenu .nav-tabs li a {
  padding: 13px 10px;
}

.dashboard-topmenu .nav-tabs li:last-child {
  margin-right: 15%;
}

@media (min-width: 900px) {
  .dashboard-topmenu .nav-tabs li:last-child {
    margin-right: 30%;
  }
}

@media (min-width: 1142px) {
  .dashboard-topmenu .nav-tabs li:last-child {
    margin-right: 40%;
  }
}

@media (min-width: 1400px) {
  .dashboard-topmenu .nav-tabs li:last-child {
    margin-right: 50%;
  }
}

@media (min-width: 1600px) {
  .dashboard-topmenu .nav-tabs li:last-child {
    margin-right: 55%;
  }
}

.graph-placeholder {
  max-width: 110px;
  height: 110px;
  border-radius: 100vh;
  background: #4d73ff;
  margin: 10px auto;
}

.graph-placeholder-square {
  max-width: 100%;
  height: 90px;
  background: #4d73ff;
  margin: 10px auto;
  border-radius: 12px;
}

.graph-placeholder-square-big {
  max-width: 100%;
  height: 300px;
  background: #4d73ff;
  margin: 10px auto;
  border-radius: 12px;
}

.calendar-selector {
  width: 420px;
}
.calendar-selector select {
  float: right;
  width: 280px;
}
.calendar-selector h5 {
  float: left;
  padding-top: 12px;
}

.widget-calendar {
  padding: 1em 1.2em;
  height: 75vh;
}
.widget-calendar .calendar-empty {
  text-align: center;
  padding-top: 10%;
  margin-bottom: 40px;
}
.widget-calendar .calendar-empty i.et-calendar {
  color: #4d73ff;
  font-size: 3em;
  margin: 40px 0;
  display: inline-block;
}

.header-dashboard-user {
  padding: 20px 0 0;
  position: relative;
  background-color: #ffffff;
}
@media (min-width: 992px) {
  .header-dashboard-user {
    padding-bottom: 20px;
  }
}
.header-dashboard-user h1,
.header-dashboard-user h2 {
  margin-bottom: 5px;
}
.header-dashboard-user h1 {
  font-weight: 500;
}
.header-dashboard-user h2.user-title {
  color: #b4b4b4;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
}

.avatar {
  width: 140px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: auto;
}

.avatar-md {
  width: 85px;
}

.avatar--dashboard {
  margin: 0 auto;
  border: 4px solid #98beff;
}
@media (min-width: 768px) {
  .avatar--dashboard {
    margin-right: 2%;
    float: left;
  }
}

.header-with-filters .btn,
.header-with-filters h1,
.header-with-filters h2,
.header-with-filters h4 {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .table-prospects > tbody > tr:nth-child(6n - 2),
  .table-prospects > tbody > tr:nth-child(6n - 1) {
    background-color: white;
  }
  .table-prospects > tbody > tr:nth-child(6n - 5),
  .table-prospects > tbody > tr:nth-child(6n - 4) {
    background-color: #f8f8f8;
  }
  .table-prospects td:last-child {
    width: 100%;
  }
  .table-section td > span.row-detail {
    display: inline-block;
    margin-right: 10px;
    font-weight: 500;
  }
  th.clickable {
    position: relative;
  }
  th span.accent-open {
    width: 7px;
    height: 7px;
  }
  th.clickable:after {
    width: 10px;
    height: 7px;
    position: absolute;
    right: 4%;
    top: 5%;
    font-size: 22px;
    color: #4d73ff;
    display: block;
  }
  th.clickable.collapsed:after {
    content: '\002b';
  }
  th.clickable.expanded:after {
    content: '\2212';
  }
  .table-prospects .collapse {
    display: none;
  }
  .table-section th {
    font-weight: normal;
  }
  .table-section td,
  .table-section th {
    width: 100%;
    display: block !important;
  }
  .table-section td.hidden-xs {
    display: none !important;
  }
  .table-prospects .table-section td {
    border-top: 0;
  }
  /*.table-prospects .table-header {
    display: none;
  }*/
}

.table-simple.table > tbody > tr > th,
.table-simple.table > tbody > tr > td {
  padding: 5px 15px;
  border: 0;
}

.widget {
  margin: 1em 0;
  padding-top: 1em;
  border-radius: 6px;
  background-color: #fff;
}
.widget h2 {
  font-weight: 400;
  font-size: 24px;
}

.widget-padding {
  padding: 1em;
}

.widget-no-padding {
  padding: 0;
}

.table > thead > tr > th {
  border-bottom: none;
}

.ledger-head {
  margin-bottom: 20px;
}
.ledger-head h2 {
  margin-top: 8px;
}
.ledger-head .button-export {
  width: 70%;
}
.ledger-head .btn-shout {
  margin-top: 0;
  margin-bottom: 0;
}

.ledger-brief {
  display: flex;
  margin-bottom: 40px;
}
.ledger-brief .ledger-item {
  background-color: #f5f5f5;
  margin: 0 0.5em;
  padding: 1.5em;
  flex-grow: 1;
  border-radius: 4px;
}
.ledger-brief .ledger-item h5 {
  font-weight: 500;
  margin-bottom: 5px;
}
.ledger-brief .ledger-item.balance a {
  font-size: 12px;
  margin-top: 14px;
}

.ledger-brief.brief-white .ledger-item {
  text-align: center;
  background-color: #fff;
  box-shadow: 2px 3px 3px -1px rgba(0, 0, 0, 0.25);
}

.ledger-brief.brief-white h5 {
  text-transform: uppercase;
  font-weight: 500;
}

.tabs-fluid {
  margin-top: 30px;
}

/* Prospect Documents */
.ledger-documents .table-documents ul.nav.nav-clean-tabs {
  list-style: none;
  border-bottom: solid 2px #e5e9ea;
}
.ledger-documents .table-documents ul.nav.nav-clean-tabs li {
  float: left;
}
.ledger-documents .table-documents ul.nav.nav-clean-tabs li a {
  display: block;
  padding: 21px 25px;
  color: #686868;
}
.ledger-documents .table-documents ul.nav.nav-clean-tabs li.active {
  border-bottom: solid 2px #4d73ff;
  position: relative;
  top: 1px;
}

.ledger-documents .table-documents .table-documents-column-actions {
  min-width: 140px;
}

.ledger-documents .table-documents a.btn.btn-tertiary,
.ledger-documents .table-documents .btn.btn-sm.btn-default {
  margin-right: 5px;
}

.legend {
  margin-bottom: 10px;
}

span.legend-color {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin: 8px;
  background-color: #b4b4b4;
  border: 1px solid #6a6a6a;
  border-radius: 2px;
}

span.legend-color--inactive {
  background-color: #fff1db;
}

span.legend-color--warning {
  background-color: #fff0f0;
}

/* Error Pages */
.errorpage-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100vh;
}
.errorpage-wrapper .errorpage-illustration {
  position: relative;
  top: 180px;
  text-align: center;
  margin: auto;
}
.errorpage-wrapper .errorpage-illustration .errornumber h1 {
  color: #4d73ff;
  font-weight: 500;
  font-size: 26em;
  letter-spacing: -45px;
  line-height: 0;
  position: relative;
  top: 210px;
}
.errorpage-wrapper .errorpage-illustration .errorgraphic,
.errorpage-wrapper .errorpage-illustration .maintenancegraphic {
  position: absolute;
  top: 220px;
  margin-left: -20em;
  left: 50%;
  width: 40em;
  z-index: 200;
}
.errorpage-wrapper .errorpage-illustration .errorgraphic img,
.errorpage-wrapper .errorpage-illustration .maintenancegraphic img {
  width: 100%;
}
.errorpage-wrapper .errorpage-illustration .maintenancegraphic {
  top: 160px;
}
.errorpage-wrapper .errorpage-message {
  z-index: 300;
  position: absolute;
  top: 65px;
  width: 100%;
  color: #151515;
}
.errorpage-wrapper .errorpage-message h2 {
  font-size: 2.8em;
}
.errorpage-wrapper .errorpage-message a.btn-tertiary {
  padding: 14px;
  clear: both;
}
.errorpage-wrapper .errorpage-message p {
  margin: 34px 0;
}

.section-header h1 {
  margin-bottom: 10px;
}

/* Accordion */
.accordion .panel-default {
  border-left: 0;
  border-right: 0;
}

.accordion .panel .panel-heading {
  background-color: #fff;
  min-height: 40px;
  color: #000;
  border-left: 0;
  border-right: 0;
  padding: 15px 5px 8px;
  font-weight: 400;
  cursor: pointer;
}

.accordion .panel-body {
  padding: 0;
}
.accordion .panel-body table {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
.accordion .panel-body table i.icon {
  font-size: 1em;
}

.panel .panel-heading.panel-heading--disabled h4 {
  color: #b4b4b4;
}

.panel .panel-heading.panel-heading--disabled .prosp-status-green {
  background-color: #b4b4b4;
}

.panel-group .panel + .panel {
  border-top: 0;
  margin-top: 0;
}

/* Application Manager */
.filter-horizontal {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 400;
}
@media (min-width: 992px) {
  .filter-horizontal {
    float: right;
    text-align: right;
  }
}
.filter-horizontal .txt-status span {
  position: relative;
  top: -15px;
  right: 5px;
  font-size: 14px;
}
.filter-horizontal .dropdown-status {
  width: 180px;
}
.filter-horizontal li {
  display: inline-block;
}

.notes-container {
  clear: both;
  margin-bottom: 30px;
  overflow: auto;
}
.notes-container textarea {
  height: 100px;
}
.notes-container .note-content {
  padding: 8px 2px;
  display: block;
  font-size: 0.95em;
}
.notes-container .note-content span.excerpt {
  display: inline-block;
  height: 58px;
  overflow: hidden;
}
.notes-container .note-content span.excerpt::after {
  content: '...';
}
.notes-container .note-content:hover {
  background-color: #fffde0;
}

.notes-date {
  font-size: 0.75em;
  display: block;
  color: #8e8e8e;
}

.creditstatus-page .modal {
  /*.results-throw {
			padding: 40px 0;
		}*/
}
.creditstatus-page .modal .status-icon-big {
  margin-bottom: 20px;
}
.creditstatus-page .modal .modal-body h2 {
  font-size: 24px;
}
.creditstatus-page .modal .modal-body h4 {
  font-size: 19px;
}

.creditstatus-page .estimate-monthly {
  margin-top: 16px;
}
.creditstatus-page .estimate-monthly input {
  max-width: 240px;
}

.creditstatus-page .final-decision {
  max-width: 500px;
  margin: auto;
  width: 70%;
}
.creditstatus-page .final-decision .btn {
  margin: 0;
}
.creditstatus-page .final-decision select.form-control {
  height: 40px;
}

.creditstatus-page .creditstatus-container {
  clear: both;
  margin-bottom: 40px;
  border-top: dotted 1px #ccc;
  border-bottom: dotted 1px #ccc;
  padding: 12px 0;
  position: relative;
}
.creditstatus-page .creditstatus-container .creditstatus-heading h5 {
  font-weight: 500;
  display: inline-block;
  float: left;
  margin-bottom: 10px;
}
.creditstatus-page .creditstatus-container .creditstatus-heading a.btn-text {
  display: inline-block;
  float: left;
  margin: 0 0 0 8px;
  padding: 0;
  font-size: 0.9em;
  position: relative;
  top: -3px;
}
.creditstatus-page
  .creditstatus-container
  .creditstatus-heading
  a.btn-text
  i.icon {
  margin-left: 2px;
}
.creditstatus-page .creditstatus-container .creditstatus-status {
  font-size: 18px;
  font-weight: 500;
  clear: both;
}
.creditstatus-page .creditstatus-container .creditstatus-status .btn {
  margin: 0;
}
.creditstatus-page
  .creditstatus-container
  .creditstatus-status
  small.unavailable {
  font-size: 14px;
  color: #9b9b9b;
}
.creditstatus-page .creditstatus-container .creditstatus-status .modal-body {
  min-height: 220px;
}
.creditstatus-page .creditstatus-container .creditstatus-meta {
  color: #9a9a9a;
}
.creditstatus-page .creditstatus-container .right-arrow {
  position: absolute;
  font-size: 26px;
  left: 50%;
  margin-left: -40px;
  top: 30px;
  opacity: 0.5;
}

.status-icon-big {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #9a9a9a;
  padding-top: 9px;
  color: #fff;
  font-size: 1.5em;
  margin: 12px auto 20px auto;
}

.status-disabled {
  background-color: #9a9a9a;
}

.status-default {
  background-color: #4d73ff;
}

.status-approved {
  background-color: #4cbb17;
}

.status-conditions {
  background-color: #5d25b2;
}

.status-declined {
  background-color: #d70000;
}

.status-warning {
  background-color: #fff;
  color: #d70000;
}

.modal-body > .row.results-throw {
  padding: 40px 0;
}

.accordion-applicationmanager .prosp-status-icon,
.check-status-indicator .prosp-status-icon {
  display: inline-block;
  padding: 4px;
  border-radius: 100px;
  width: 25px;
  text-align: center;
  height: 25px;
  color: #fff;
  margin-right: 10px;
}
.accordion-applicationmanager .prosp-status-icon i,
.check-status-indicator .prosp-status-icon i {
  font-size: 15px;
}

.accordion-applicationmanager .btn-text,
.check-status-indicator .btn-text {
  font-size: 0.9em;
  padding: 0;
  text-align: center;
  width: 140px;
}

.accordion-applicationmanager .btn-text:hover,
.check-status-indicator .btn-text:hover {
  color: #2136e1;
}

.accordion-applicationmanager .name-display,
.check-status-indicator .name-display {
  height: 25px;
  overflow: hidden;
  color: #444;
}

.accordion-applicationmanager .prosp-status-green,
.check-status-indicator .prosp-status-green {
  background-color: #5eaf04;
}

.accordion-applicationmanager .prosp-status-red,
.check-status-indicator .prosp-status-red {
  background-color: #e53c50;
}

.accordion-applicationmanager .prosp-status-grey,
.check-status-indicator .prosp-status-grey {
  background-color: #b4b4b4;
}

.prosp-name {
  display: inline-block;
}

.panel-title .prosp-label {
  color: #4c4c4c;
  border: solid 1px #4c4c4c;
  font-size: 0.85em;
  font-weight: 500;
  display: inline-block;
  padding: 4px 1.3em;
  min-width: 120px;
  text-align: center;
}

.panel-title .panel-button {
  color: #4d73ff;
  float: right;
}

td.prosp-actions-col {
  min-width: 160px;
}

.calendar__title {
  margin: 8px 0 0;
}

/* Convert to Applicant */
.warning-header {
  border: solid 1px #d70000;
  background-color: #fff;
  width: 98%;
  margin: 20px auto;
  font-weight: 400;
  padding: 15px;
  min-height: 80px;
}
.warning-header .warning-symbol {
  background-color: #d70000;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: auto;
  color: #fff;
  padding: 13px;
  font-size: 1.3em;
  float: left;
  font-weight: 500;
}
.warning-header .warning-message {
  float: left;
  margin-left: 1.5%;
  padding-top: 9px;
  width: 90%;
}

.leaseform-header {
  display: block;
  min-height: 40px;
  margin-bottom: 20px;
}
.leaseform-header h1 {
  font-weight: 500;
}
.leaseform-header h4 {
  margin-top: 20px;
}

.applicant-convert-header {
  margin: 30px 0;
}
.applicant-convert-header .unittype-menu {
  width: 250px;
  float: right;
}
.applicant-convert-header .unittype-menu label {
  float: left;
  padding-top: 9px;
  margin-right: 10px;
}
.applicant-convert-header .unittype-menu .form-group {
  float: left;
  width: 160px;
}
.applicant-convert-header .unittype-menu .form-group select {
  width: 100%;
}

.applicant-convert-header .form-header {
  margin-top: 0;
}

.applicant-convert-form input[type='text'],
.applicant-convert-form .form-control {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding-left: 7px;
  padding-right: 7px;
}

.applicant-convert-form .container-of-inputs {
  width: 95%;
  min-height: 30px;
  float: left;
  margin-bottom: 10px;
}

.applicant-convert-form div[class*='col-'] {
  padding: 0 3px;
}

.applicant-convert-form .container-of-trash {
  width: 5%;
  float: right;
  margin-bottom: 10px;
}
.applicant-convert-form .container-of-trash .et-trash,
.applicant-convert-form .container-of-trash .et-pencil {
  position: relative;
  top: 4px;
  left: 15px;
}

.applicant-grp {
  margin-bottom: 40px;
}
.applicant-grp .main-signer {
  font-weight: 500;
}

.section-leaseappform {
  width: 98%;
  margin: 10px auto 20px auto;
  padding: 5%;
  background-color: #fff;
}
.section-leaseappform .block-heading {
  min-height: 38px;
}
.section-leaseappform .tag-required {
  margin-left: 20px;
}
.section-leaseappform .btn-print {
  font-size: 1.8em;
  min-width: auto;
}
@media (min-width: 1200px) {
  .section-leaseappform {
    width: 96%;
    margin: 10px auto 20px auto;
    /*max-width: 1140px;*/
    max-width: 1260px;
    padding: 3em;
  }
}
.section-leaseappform .formblock-active {
  background-color: #fff;
  padding: 1em;
  clear: both;
  width: 98%;
  margin: auto;
  border: solid 1px #e2e2e2;
}
.section-leaseappform .form-content {
  margin: auto;
  padding: 0 5%;
}
@media (min-width: 1200px) {
  .section-leaseappform .form-content {
    max-width: 95%;
    padding: 0;
  }
}
.section-leaseappform .row-title {
  font-style: italic;
  color: #9b9b9b;
  padding-top: 10px;
  height: 68px;
}

span.addocc-user {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}
span.addocc-user .icon {
  opacity: 0.7;
  margin-right: 7px;
}

.guidelines-container {
  height: 300px;
  border: solid 1px #e8e8e8;
  overflow-y: auto;
  padding: 5px;
  font-size: 0.9em;
  text-align: justify;
}
.guidelines-container .subheading {
  background-color: #e0e0e0;
  padding: 12px;
  margin-bottom: 12px;
}
.guidelines-container .subheading h5 {
  margin-bottom: 0;
}
.guidelines-container .indented {
  width: calc(100%-33px);
  margin-left: 33px;
}
.guidelines-container p {
  margin-bottom: 12px;
}

.leaseapp-header .instructions {
  border-top: solid 1px #d1d1d1;
  padding: 18px 4px;
}

.signature-container {
  width: 70%;
  margin: 30px auto;
}

/* Add Prospect */
.form-wrapper {
  width: 96%;
  margin: auto;
  font-size: 0.99em;
  margin-bottom: 60px;
}
.form-wrapper .additional-occupants-head {
  max-width: 550px;
  clear: both;
  margin-bottom: 14px;
}
.form-wrapper .additional-occupants-head h3 {
  display: inline-block;
  float: left;
  margin-right: 20px;
  padding-top: 16px;
  font-weight: 500;
}
.form-wrapper .additional-occupants-head .head-subtitle {
  float: left;
  margin-right: 20px;
  padding-top: 12px;
  font-weight: 500;
}
.form-wrapper .additional-occupants-head .head-selector {
  float: left;
}
.form-wrapper .additional-occupants-head .head-selector select {
  width: 120px;
}
.form-wrapper .additional-occupants-head .form-options {
  margin-top: 14px;
}

.applicant-subgrp {
  margin-bottom: 50px;
  border-bottom: dotted 1px #ccc;
}

.form-header {
  margin: 35px 0 0;
}
.form-header h2 {
  margin-right: 20px;
  font-size: 1.6em;
}

/* Sticky note modal */
.modal .modal-stickynote {
  background-color: #d9eff3;
  height: 375px;
  top: 20vh;
  position: relative;
}

.modal .stickynote-content {
  padding: 2% 6%;
  top: 50px;
  position: absolute;
  font-size: 1.3em;
  line-height: 30px;
  height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: justify;
}
.modal .stickynote-content .disabled {
  opacity: 0.4;
}

.modal .stickynote-header {
  padding: 10px 4%;
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  font-size: 1.05em;
}
.modal .stickynote-header a {
  text-transform: uppercase;
  font-weight: 500;
}
.modal .stickynote-header a i.icon {
  margin-right: 5px;
}
.modal .stickynote-header a.undo {
  color: #767676;
}
.modal .stickynote-header span.saving {
  padding-top: 10px;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 400;
}
.modal .stickynote-header span.stickyerror {
  background-color: #5d25b2;
  display: inline-block;
  color: #fff;
  padding: 5px 14px;
  font-size: 0.9em;
  position: relative;
  top: 8px;
}

.modal .stickynote-footer {
  padding: 13px 6%;
  position: absolute;
  bottom: 0;
  height: 46px;
  width: 100%;
  font-size: 0.85em;
  color: #287d8c;
  border-top: dotted 1px #287d8c;
}

/* --- PAGE: Timeout --- */
.timeoutwrapper .login-container {
  padding: 5px 10% 45px 10%;
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.18);
}

.timeout-remaining {
  text-align: center;
  font-size: 1.7em;
  margin: 10px 0;
}

/* Calendar Fixes */
.box-calendar .rbc-event,
.box-calendar .rbc-event-allday {
  font-size: 0.8em;
}

.box-calendar .rbc-label {
  font-size: 0.9em;
}

.box-calendar th.rbc-header {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #4d73ff;
}

/* Purple Info Box*/
.highlight-details {
  width: auto;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid #7734e7;
  background-color: #f2edf3;
}
.highlight-details span {
  margin: 0 5px;
}
.highlight-details span:nth-child(2):before {
  content: '|';
  margin-right: 10px;
}
.highlight-details span:nth-child(2):after {
  content: '|';
  margin-left: 10px;
}
.highlight-details span.no-third:after {
  content: '';
}

.notes-date {
  display: block;
  width: 100%;
  font-size: 13px;
}

/* Payments */
.table-payments .et-alert-urgent {
  font-size: 1.6em;
}

.appmanagertitle small {
  color: #adadad;
  font-weight: 500;
  display: block;
  margin-top: 10px;
}

.batch-status {
  font-weight: 500;
  margin-bottom: 20px;
}

/* Printable Quote */
.quote-printable {
  width: 100%;
  max-width: 1060px;
  padding: 20px;
  margin: auto;
  background-color: #fff;
  color: #696969;
}
.quote-printable .logo-placeholder {
  margin-bottom: 30px;
}
.quote-printable .logo-placeholder img {
  width: 80%;
}
.quote-printable .btn-print {
  font-size: 1.3em;
}
.quote-printable .quote-header {
  border-bottom: dotted 1px #ccc;
  margin-bottom: 20px;
}
.quote-printable h2 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.quote-printable .quote-description,
.quote-printable .quote-info,
.quote-printable .quote-custom,
.quote-printable .quote-specials {
  margin-bottom: 35px;
}
.quote-printable .quote-info .row {
  margin-bottom: 20px;
}
.quote-printable .quote-info span.info-value {
  font-weight: 400;
  /*text-transform: uppercase;*/
  font-size: 17px;
  display: block;
}
.quote-printable .quote-info label {
  margin-bottom: 5px;
}
.quote-printable .table-quote {
  font-size: 1em;
  min-width: 600px;
}
.quote-printable .table-quote th {
  padding: 12px 8px 12px 0;
}
.quote-printable .table-quote .indented {
  padding-left: 22px;
}
.quote-printable .print-strip {
  width: 100%;
  height: 33px;
  border-bottom: dashed 1px #ccc;
  display: block;
}
.quote-printable .footer-address {
  font-size: 0.9em;
  margin-top: 30px;
  font-weight: 400;
}
.quote-printable .quote-gallery img {
  margin-bottom: 20px;
  width: 100%;
}

/* Household for vehicles */
.modal-vehicle-hh {
  padding-top: 0;
}
.modal-vehicle-hh .modal-confirm {
  min-height: 480px;
}

.vehicle-list {
  width: 98%;
  margin: 0 auto;
}

.modal-body.modal-vehicle-hh {
  padding-bottom: 16px;
}
.modal-body.modal-vehicle-hh .no-vehicle input[type='checkbox'] {
  margin: 0 5px 0 0;
  position: relative;
  top: 13px;
}

/* Convert to Resident */
.resident-convert-form {
  max-width: 990px;
  margin: 20px 0 0 20px;
}
.resident-convert-form .list-movein-actions li {
  margin-bottom: 14px;
  font-weight: 500;
}

.resident-expand-container {
  max-width: 1120px;
}

/* View Unit */
.page-unit-view {
  margin-top: 20px;
}
.page-unit-view .panel {
  padding-bottom: 14px;
}
.page-unit-view .panel .unit-details .row {
  padding: 5px 0;
}
.page-unit-view .unit-head {
  padding: 0 10px;
}
.page-unit-view .unit-head h1,
.page-unit-view .unit-head h4 {
  display: inline-block;
}
.page-unit-view .unit-head h1 {
  font-size: 2em;
  margin-right: 8px;
}
.page-unit-view .unit-head h4 {
  font-size: 1.4em;
  color: #6a6a6a;
}
.page-unit-view .unit-overview .average-income-details {
  display: inline-block;
  margin-left: 5px;
  padding: 5px;
  border: 2px solid #9a9a9a;
  color: #9a9a9a;
  text-transform: uppercase;
  font-weight: 500;
}
.page-unit-view .unit-overview .unit-properties {
  padding: 10px;
}
.page-unit-view .unit-overview .unit-properties .unit-prop-att {
  clear: both;
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 0.9em;
}
.page-unit-view .unit-overview .unit-properties .unit-prop-value {
  font-size: 1.8em;
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
}
.page-unit-view .unit-overview .unit-properties .unit-prop-value h1,
.page-unit-view .unit-overview .unit-properties .unit-prop-value i.icon {
  display: inline-block;
}
.page-unit-view .unit-overview .unit-properties .unit-prop-value h1 {
  font-weight: 400;
  margin-right: 8px;
  margin-bottom: 5px;
  font-size: 29px;
}
.page-unit-view .unit-overview .unit-properties .unit-prop-value i.icon {
  font-size: 26px;
  color: #4d73ff;
  position: relative;
  top: 2px;
}
.page-unit-view .unit-charges {
  padding-bottom: 0;
}
.page-unit-view .unit-charges .panel-head {
  margin-bottom: 0;
}
.page-unit-view table {
  font-size: 1em;
  width: 100%;
}
.page-unit-view table tr {
  height: 30px;
}
.page-unit-view .table-active {
  background-color: #4d73ff !important;
}

.logo-placeholder {
  padding: 15px;
  text-align: center;
}
.logo-placeholder img {
  width: 50%;
}
@media (min-width: 992px) {
  .logo-placeholder img {
    width: 80%;
  }
}

.form-steps .card label.checkbox,
.form-steps .card .checkbox span {
  font-weight: 500;
}

.page-unit-view .panel .panel {
  padding-bottom: 0;
}

.renewal-link {
  display: inline-block;
  margin-top: 5px;
}
@media (min-width: 1300px) {
  .renewal-link {
    margin-top: 20px;
  }
}

.block-snapshot h2.people-profile-left {
  width: 38%;
}

h2.people-profile-left {
  width: auto;
}

.block-snapshot .panel-heading .custom-checkbox label {
  font-weight: 500;
}

.block-snapshot .panel-heading .custom-checkbox input {
  position: absolute;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.column-eq-height {
  height: 92%;
}

.column-eq-height h5 {
  font-size: 0.75em;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6em;
}

/* Manage Communication custom */
.communication-recipients-wrap .panel__details--full {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.communication-recipients-wrap .panel-head {
  margin-bottom: 0;
}

.communication-recipients-wrap table tr td {
  padding: 14px 8px;
}

.communication-recipients-wrap table tr th {
  vertical-align: bottom;
}

/* Modal HUD Deductions */
.modal-deductions .modal-confirm {
  display: block;
  height: 300px;
  min-height: 0;
  position: relative;
}

/* Walk Me Change */
.mainbody.extended .top-right-admin-bar .user-name {
  display: none;
}

.mainbody.extended .top-right-admin-bar {
  right: 180px;
}
@media (min-width: 1200px) {
  .mainbody.extended .top-right-admin-bar {
    right: 162px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 1780px) {
  .mainbody.extended .top-right-admin-bar {
    right: 142px;
  }
}

.mainbody.extended .top-right-admin-bar .btn#dropdown-usermenu .caret {
  margin-left: 7px;
}

/* Form Basics */
form {
  font-size: 13px;
}

label,
label.control-label {
  color: black;
  font-size: 14px;
  font-weight: 500;
  min-height: 16px;
  display: inline-block;
}

label.required::after,
h5.required::after,
h3.required::after,
h2.required::after {
  content: '*';
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  line-height: 3px;
  position: relative;
  top: 2px;
  left: 2px;
  /*color: #969696;*/
  color: #6a6a6a;
  font-weight: 400;
}

h3.required::after {
  color: #4d73ff;
}

.required.double::after {
  content: '**';
}

.form-footernote {
  color: #646464;
  font-size: 13px;
  margin: 10px 0 25px 0;
}

.form-group textarea.form-control {
  padding: 10px 12px 0;
}

textarea.textarea-short {
  height: 90px;
  padding-top: 10px;
  overflow: auto;
  font-size: 13px;
}

.checkbox label,
.radio label {
  padding-left: 6px;
}

.inline-item {
  display: inline-block;
  vertical-align: middle;
}

input,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.input-lg,
.input-md {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 7px;
  padding-left: 7px;
  font-size: 14px;
  font-weight: normal;
  line-height: 42px;
}

.input-md {
  height: 30px;
  font-size: 14px;
}

input[type='number'] {
  padding-left: 9px;
  padding-right: 0;
}

_::-webkit-full-page-media,
_:future,
:root input[type='text'] {
  line-height: 19px;
}

.input-group-sm .input-sm {
  height: 24px;
}

.form-control .input-sm {
  border-radius: 3px;
  box-shadow: 0;
}

.profile-view-edit .input-sm {
  margin: 7px 0;
}

select.input-sm {
  height: 24px;
}

.input-group.inline-input {
  width: 100%;
}

.form-control {
  resize: none;
}

.form-group {
  position: relative;
  margin-bottom: 1.1rem;
  margin-bottom: 1rem;
}
.form-group input[type='text'],
.form-group input[type='password'],
.form-group textarea {
  width: 100%;
  border-radius: 3px;
  padding: 0 6px;
  line-height: 1.5em;
}
.form-group textarea {
  height: 120px;
}

.form-section {
  margin-bottom: 6%;
  display: none;
}
@media only screen and (min-width: 768px) {
  .form-section {
    display: block;
  }
}

.form-reset .form-section {
  display: block;
}

/* Custom Upload buttons */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile-custom + label {
  color: #4d73ff;
}

.inputfile-custom + label {
  border: 1px solid #d1d1d1;
  background-color: #fff;
  padding: 0;
  border-radius: 0.25em;
}

.inputfile-custom:focus + label,
.inputfile-custom.has-focus + label,
.inputfile-custom + label:hover {
  border-color: #4d73ff;
}

.inputfile-custom + label span,
.inputfile-custom + label strong {
  padding: 8px 1.25rem;
  /* 10px 20px */
}

.inputfile-custom + label span {
  width: 182px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
  color: #666;
  font-weight: 400;
}

.inputfile-custom + label strong {
  height: 100%;
  color: #fff;
  background-color: #4d73ff;
  display: inline-block;
}

.inputfile-custom:focus + label strong,
.inputfile-custom.has-focus + label strong,
.inputfile-custom + label:hover strong {
  background-color: #4d73ff;
}

@media screen and (max-width: 50em) {
  .inputfile-custom + label strong {
    display: block;
  }
}

/* Floating Label Placeholder */
/*
.has-float-label .form-control:placeholder-shown:not(:focus)+* {
	font-size: 15px;
	font-weight: normal;
	top: 12px;
	text-transform: capitalize;

}

.has-float-label>span {
	top: .75em;
	left: 14px;
	color: darkgrey;
	font-size: 55%;
	font-weight: normal;
	text-transform: uppercase;
}

.has-float-label .input-lg {
	padding-top: 16px;
}*/
/* Hyphen between range elements */
.has-range:after {
  content: '';
  height: 1px;
  width: 12px;
  display: none;
  position: absolute;
  background-color: #b4b4b4;
  margin-top: 4px;
  right: -5.5px;
  top: 45px;
}
@media only screen and (min-width: 768px) {
  .has-range:after {
    display: block;
  }
}

.has-range-date:after {
  content: 'to';
  display: none;
  position: absolute;
  right: -4px;
  top: 40px;
  font-size: 11px;
}
@media only screen and (min-width: 768px) {
  .has-range-date:after {
    display: block;
  }
}

.has-range-nolabel:after {
  top: 15px;
}

/* Custom Checkbox */
.checkbox span {
  display: block;
}

.custom-checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  opacity: 0;
  visibility: hidden;
}

/* Styles for custom checkbox click event */
.custom-check-square,
span.custom-check-square {
  /* position: absolute;
	top: 3px;
	left: -20px;
	height: 14px;
	width: 14px;
	background-color: white;
	border: 1px solid lightgray;
	border-radius: 2px;*/
  position: relative;
  top: 3px;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 1px solid #707177;
  border-radius: 2px;
  display: inline-block;
  margin-right: 10px;
}

.custom-check-square.center-block,
span.custom-check-square.center-block {
  position: relative;
  left: auto;
}

.custom-check-square:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .custom-check-square:after {
  display: block;
}

.custom-checkbox .custom-check-square:after {
  left: 3px;
  top: 3px;
  width: 6px;
  height: 6px;
  background-color: #4d73ff;
}

.custom-checkbox .custom-check-square-disabled:after {
  background-color: #6a6a6a;
}

/*Disabled Checkbox Attributes*/
.block-snapshot
  .custom-checkbox
  input[type='checkbox'][disabled]
  ~ .custom-check-square {
  background-color: #ffffff;
  cursor: not-allowed;
  border: 1px solid #b1b2b5;
}

.custom-checkbox input[type='checkbox'][disabled] ~ .custom-check-square {
  background-color: #edeff1;
  cursor: not-allowed;
}

.custom-checkbox
  input[type='checkbox'][disabled]:checked
  ~ .custom-check-square {
  background-color: #ffffff;
}

.custom-checkbox
  input[type='checkbox'][disabled]:checked
  ~ .custom-check-square:after {
  background-color: #b4b4b4;
}

.flying-checkbox {
  display: inline-block;
  margin: 0 0 0 26px;
  padding-bottom: 2px;
}
.flying-checkbox label {
  min-height: 0;
}
.flying-checkbox .custom-check-square,
.flying-checkbox span.custom-check-square {
  position: absolute;
  left: -20px;
}

.custom-checkbox.padleft20 {
  margin-left: 0;
}
.custom-checkbox.padleft20 label {
  padding-left: 0;
}

/* Select */
.form-select {
  line-height: 16px !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 3;
  overflow: hidden;
  border-radius: 0.25em;
}

.select select {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 0.5em;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
}

select::-ms-expand {
  display: none;
}

.select::after {
  content: '\25BC';
  color: #4d73ff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  pointer-events: none;
}

select.input-lg,
select.input-md {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* Theme Multiselector */
.msts_theme_example {
  border: 1px solid silver;
}

.msts_theme_example .msts__heading .msts__side {
  padding: 5px;
  text-align: center;
  color: #000000;
  font-weight: 500;
}

.msts_theme_example .msts__heading .msts__side_available {
  background-color: #ffffff;
}

.msts_theme_example .msts__heading .msts__side_selected {
  background-color: #ffffff;
  /* margin-left: 14px; */
}

.msts_theme_example .msts__subheading .msts__side_filter {
  padding: 5px;
}

.msts_theme_example .msts__footer .msts__side {
  padding: 5px 15px;
  background-color: #f0f0f0;
  font-size: 1em;
  color: #7f8c8d;
  text-align: right;
}

.msts_theme_example .msts__list {
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}

.msts_theme_example .msts__list-item {
  padding: 5px 10px;
  -webkit-transition: background-color ease-in 0.1s, color ease-in 0.1s;
  -o-transition: background-color ease-in 0.1s, color ease-in 0.1s;
  transition: background-color ease-in 0.1s, color ease-in 0.1s;
}

.msts_theme_example .msts__list-item:hover {
  background-color: #4d73ff;
  color: #fff;
}

.msts_theme_example .msts__list-item_disabled {
  background-color: #ecf0f1;
  color: #7f8c8d;
}

.msts_theme_example .msts__list-item_disabled:hover {
  background-color: #ecf0f1;
  color: #7f8c8d;
}

.msts_theme_example .msts__list-item_highlighted {
  background-color: rgba(41, 128, 185, 0.25);
}

.msts_theme_example .msts__control {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 3px;
  color: #4d73ff;
  -webkit-transition: color ease 0.15s;
  -o-transition: color ease 0.15s;
  transition: color ease 0.15s;
}

.msts_theme_example .msts__control:hover {
  color: #95a5a6;
}

.msts_theme_example .msts__control[disabled] {
  color: #ecf0f1;
}

.msts_theme_example .msts__control_select-all:after {
  content: '\e92f';
}

.msts_theme_example .msts__control_deselect-all:after {
  content: '\e92e';
}

.msts_theme_example .msts__filter {
  position: relative;
}

.msts_theme_example .msts__filter-input {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid silver;
}

.msts_theme_example .msts__filter-clear {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 1.25em;
  color: silver;
  -webkit-transition: color ease 0.15s;
  -o-transition: color ease 0.15s;
  transition: color ease 0.15s;
}

.msts_theme_example .msts__filter-clear:after {
  content: '\D7';
  vertical-align: middle;
}

.msts_theme_example .msts__filter-clear:hover {
  color: #c0392b;
}

.msts_theme_example.msts_disabled {
  background-color: #ecf0f1;
}

.msts_theme_example .msts {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.msts_theme_example .msts__heading {
  display: -ms-flexbox;
  display: flex;
}

.msts_theme_example .msts__subheading {
  display: -ms-flexbox;
  display: flex;
}

.msts_theme_example .msts__body {
  display: -ms-flexbox;
  display: flex;
}

.msts_theme_example .msts__footer {
  display: -ms-flexbox;
  display: flex;
}

.msts_theme_example .msts__side {
  width: 50%;
}

.msts_theme_example .msts__side_controls {
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.msts_theme_example .msts__list {
  padding: 0;
  margin: 0;
}

.msts_theme_example .msts__list-item {
  list-style-type: none;
  cursor: pointer;
}

.msts_theme_example .msts__list-item_disabled {
  cursor: default;
}

#style-5::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
  background-color: #0ae;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

/* Two column selector */
.msts,
.msts.msts_theme_example {
  margin: 20px 0;
  position: relative;
  background: white;
  border: 1px solid #dadada;
}

.msts_theme_example .msts__heading {
  display: -ms-flexbox;
  display: flex;
  padding: 1% 6px 0;
}

.msts_theme_example .msts__subheading,
.msts_theme_example .msts__body,
.msts_theme_example .msts__footer {
  display: -ms-flexbox;
  display: flex;
}

.msts_theme_example .msts__heading .msts__side_available,
.msts_theme_example .msts__heading .msts__side_selected {
  background-color: transparent !important;
}

.msts_theme_example .msts__heading .msts__side {
  padding: 5px;
  color: #4e4e4e !important;
  font-weight: 400 !important;
  text-align: left !important;
}

.msts_theme_example .msts__side.msts__side_available {
  width: 48% !important;
  margin-right: 2%;
}

.msts_theme_example .msts__side.msts__side_selected {
  width: 48% !important;
  margin-left: 2%;
}

.msts_theme_example .msts__filter input {
  font-size: 13px;
}

.msts_theme_example .msts__subheading .msts__side_filter {
  padding: 5px;
}

.msts_theme_example .msts__side.msts__side_filter {
  width: 48% !important;
}

.msts_theme_example .msts__side.msts__side_filter:first-child {
  margin-right: 2%;
}

.msts_theme_example .msts__side.msts__side_filter:nth-child(n + 2) {
  margin-left: 2%;
}

.msts_theme_example .msts__footer .msts__side {
  font-size: 0.75em !important;
  border-top: 1px solid #dadada;
}

.msts_theme_example .msts__list-item_highlighted {
  background-color: #ebf5ff;
}

.msts_theme_example .msts__side_controls {
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.msts_theme_example .msts__side.msts__side_controls {
  width: 4% !important;
  background-color: #f0f0f0;
  position: absolute;
  left: 48%;
  height: 100%;
  top: 0;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.msts_theme_example.msts-disabled:before {
  content: '';
  cursor: not-allowed;
  background-color: #f3f2f2;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 4;
  opacity: 0.3;
  cursor: not-allowed;
}

.msts_theme_example.msts-disabled {
  background-color: #f3f2f2;
}

.msts-disabled .msts__filter-input {
  background-color: #f3f2f2;
  border-left: 1px solid #dadada;
}

.msts_theme_example .msts__list {
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}

.msts_theme_example .msts__list {
  padding: 0;
  margin: 0;
}

.msts_theme_example .msts__list-item {
  padding: 5px 10px;
  -webkit-transition: background-color ease-in 0.1s, color ease-in 0.1s;
  -o-transition: background-color ease-in 0.1s, color ease-in 0.1s;
  transition: background-color ease-in 0.1s, color ease-in 0.1s;
}

.msts_theme_example .msts__list-item {
  list-style-type: none;
  cursor: pointer;
}

.msts_theme_example .msts__list-item_highlighted {
  background-color: rgba(41, 128, 185, 0.25);
}

.msts_theme_example .msts__control_select-all:after {
  content: '\e92f';
  font-family: 'eticons';
}

.msts_theme_example .msts__control_deselect-all:after {
  content: '\e92e';
  font-family: 'eticons';
}

.msts.msts_theme_example.msts-error {
  border: 1px solid #b10303;
}

.has-error,
.has-error .react-multi-select__placeholder,
.has-error .react-multi-select__indicator {
  color: #b10303;
  font-weight: 500;
}

.has-error .form-control,
.has-error .react-multi-select__control,
.has-error .react-multi-select__control:hover {
  border: 2px solid #b10303;
}

.has-error .react-multi-select__indicator-separator {
  background-color: #b10303;
  color: #b10303;
}

.has-error .react-multi-select__control:focus {
  border-color: #97221b;
  box-shadow: 0 0 6px #e46e67;
}

.has-error .radio-inline,
.has-error .help-block {
  font-weight: 500;
}

.has-error-yellow {
  background-color: #fbffb9;
  border: solid 1px #dadada;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  background: transparent !important;
}

.border-red {
  border-color: #b10303 !important;
}

/*
.input-group {
	width: 100%;
	clear: both;
	.input-group-prepend {
		width: 33px;
		float: left;
		.input-group-text {
			background-color: #F8F8F8;
			height: 31px;
			padding: 5px 0px;
			text-align: center;
			font-size: 0.8em;
			border: solid 1px #dedede;
			border-right: 0;
			border-radius: 4px 0 0 4px;
		}
	}
}

*/
.form-groupbox {
  border: solid 1px #ebf5ff;
  padding: 4%;
  min-height: 245px;
}

.form-groupbox--scrollable {
  min-height: 1px;
  height: 150px;
  overflow: auto;
}

.form-groupbox--flexible {
  min-height: 147px;
  height: auto;
}

.container-of-inputs .checkbox label {
  padding: 0;
}

.container-of-inputs .checkbox {
  margin-top: 4px;
}

.container-of-inputs span.custom-check-square {
  position: relative;
  text-align: center;
  top: 0;
  left: auto;
  margin: 0;
  padding: 0;
}

.section-leaseappform .form-options {
  margin-bottom: 12px;
}

.form-option-inline {
  width: 46%;
  display: inline-block;
  margin-right: 2%;
}
@media (min-width: 1200px) {
  .form-option-inline {
    width: auto;
    display: inline-block;
  }
}

.radio-with-icons {
  text-align: center;
  cursor: pointer;
}
.radio-with-icons i {
  width: 100%;
  margin-bottom: 15px;
  display: block;
  font-size: 30px;
}

.radio-with-icons.selected i {
  color: #4d73ff;
}

.input-group__pet-control i {
  color: #4d73ff;
  font-size: 18px;
  margin-right: 5px;
}

.input-group__pet-control span.custom-check-square {
  position: relative;
  margin-right: 0;
  display: inline-block;
  left: -14px;
}

.input-group__pet-control .checkbox {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .form-group__notimespec {
    padding-top: 22px;
  }
}

@media (min-width: 1200px) {
  .form--narrow {
    width: 82%;
    margin-left: 2%;
  }
}

.form-content {
  margin-top: 2%;
}

.form-block {
  margin-top: 10px;
  margin-bottom: 25px;
}

.form-footer {
  text-align: center;
}
.form-footer .custom-checkbox {
  width: 430px;
  text-align: center;
  display: inline-block;
}
@media (min-width: 1200px) {
  .form-footer .custom-check-square,
  .form-footer span.custom-check-square {
    left: -12px;
    top: 4px;
  }
}
.form-footer .btn {
  min-width: 200px;
  margin-right: 3%;
}

/* Steps form */
.form-steps .btn-switch {
  margin-top: 10%;
}

.form-steps h3 {
  font-size: 1.2em;
}

.form-steps ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.form-steps ul li {
  float: left;
  margin-right: 20px;
  font-weight: 500;
}

.form-steps ul input {
  margin-right: 10px;
}

.select-sm {
  width: 60px !important;
}

.input-group-addon {
  padding-right: 8px;
  padding-left: 8px;
  font-size: 14px;
}
.input-group-addon .et-icons16 {
  font-size: 14px;
}

#peoples-tab-pane-6 .rdtPicker,
.panel-body .rdtPicker,
.unit-information .rdtPicker {
  right: -1px;
}

.email-field:valid {
  border-color: black;
}

.email-field:invalid {
  border-color: red;
}

/* Fix for inline options not aligning*/
.radio-inline + .radio-inline {
  margin-right: 25px;
  margin-left: 0;
  line-height: 1.7em;
}

.radio-inline {
  margin-right: 25px;
}

.section-header .btn {
  margin-top: 10px;
}

.section-header .btn-shout,
.section-header .btn-callto {
  margin-right: 4px;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .section-header .btn-shout,
  .section-header .btn-callto {
    width: 100%;
    margin: 10px 0;
  }
}

.section-header .btn.no-margin-top {
  margin-top: 0;
}

@media (max-width: 768px) {
  .section-header h1 {
    width: 40%;
  }
}

.block-heading {
  background-color: #ececec;
  color: #4e4e4e;
  /*font-weight: 500;*/
  padding: 12px 10px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.block-heading h1,
.block-heading h2,
.block-heading h3,
.block-heading h4,
.block-heading h5 {
  margin-bottom: 0;
}
.block-heading a.btn-text {
  padding: 0;
}

.section-leaseappform .block-heading {
  margin-top: 20px;
}

.form-header i {
  margin-right: 10px;
  color: #4d73ff;
  font-size: 22px;
}

.unit-head h5 {
  font-family: 'Roboto', sans-serif, sans-serif;
}

.h5-alt {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
}

/* Margins */
.padleft5 {
  margin-left: 5px;
}

.padleft10 {
  margin-left: 10px;
}

.padleft15 {
  margin-left: 15px;
}

.padleft20 {
  margin-left: 20px;
}

.padleft30 {
  margin-left: 30px;
}

.padright5 {
  margin-right: 5px;
}

.padright10 {
  margin-right: 10px;
}

.padright15 {
  margin-right: 15px;
}

.padright20 {
  margin-right: 20px;
}

.padright30 {
  margin-right: 30px;
}

.padbottom5 {
  margin-bottom: 5px;
}

.padbottom10 {
  margin-bottom: 10px;
}

.padbottom15 {
  margin-bottom: 15px;
}

.padbottom20 {
  margin-bottom: 20px;
}

.padbottom30 {
  margin-bottom: 30px;
}

.padtop1 {
  margin-top: 1px;
}

.padtop2 {
  margin-top: 2px;
}

.padtop3 {
  margin-top: 3px;
}

.padtop5 {
  margin-top: 5px;
}

.padtop7 {
  margin-top: 7px;
}

.padtop10 {
  margin-top: 10px;
}

.padtop15 {
  margin-top: 15px;
}

.padtop17 {
  margin-top: 17px;
}

.padtop20 {
  margin-top: 20px;
}

.padtop30 {
  margin-top: 30px;
}

.up10 {
  margin-top: -10px;
}

/* Positioners */
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

@media (min-width: 1200px) {
  .pull-right-md {
    float: right;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.vertical-align-middle > * {
  vertical-align: middle;
}

.vertical-align-top > * {
  vertical-align: top;
}

.vertical-align-bottom > * {
  vertical-align: bottom;
}

/* Text Helpers */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-blue {
  color: #4d73ff;
}

.text-red {
  color: #d70000;
}

.text-green {
  color: #4cbb17;
}

.text-orange {
  color: #5d25b2;
}

.text-gray {
  color: #b4b4b4;
}

.text-gray--darker {
  color: #787b7d;
}

.no-border {
  border: none;
}

.no-border-top {
  border-top: none;
}

.font-11 {
  font-size: 10px;
}

.font-10 {
  font-size: 0.71em;
}

.font-12 {
  font-size: 0.75em;
}

.font-14 {
  font-size: 1em;
}

.font18 {
  font-size: 1.5em;
}

.font25 {
  font-size: 1.6em;
}

.kpiFontSize {
  font-size: 1.8em;
}

.font30 {
  font-size: 2.2em;
}

/* Weights */
.semibold {
  font-weight: 500;
}

.font-light {
  font-weight: 400;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: 500;
}

.font-bold-important {
  font-weight: 500 !important;
}

/* Reseters */
.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.modal-content .no-padding {
  padding: 0px;
}

.print-only {
  display: none;
}

.nopad {
  padding: 0px;
  margin: 0px;
}

.nopadtop,
.modal-body > form.nopadtop {
  padding-top: 0;
}

/* Table Widths */
.table-col-width65,
th.table-col-width65 {
  min-width: 65px !important;
}

.table-col-width150,
th.table-col-width150 {
  min-width: 150px !important;
}

.table-col-width180,
th.table-col-width180 {
  min-width: 180px !important;
}

.table-col-width200,
th.table-col-width200 {
  min-width: 200px !important;
}

.table-col-width210,
th.table-col-width210 {
  min-width: 210px !important;
}

/* Buttons */
@media (max-width: 767px) {
  .btn-xs-block {
    display: block;
    width: 100%;
  }
  input[type='submit'].btn-xs-block,
  input[type='reset'].btn-xs-block,
  input[type='button'].btn-xs-block {
    width: 100%;
  }
  .btn-block + .btn-xs-block,
  .btn-xs-block + .btn-block,
  .btn-xs-block + .btn-xs-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
  input[type='submit'].btn-sm-block,
  input[type='reset'].btn-sm-block,
  input[type='button'].btn-sm-block {
    width: 100%;
  }
  .btn-block + .btn-sm-block,
  .btn-sm-block + .btn-block,
  .btn-sm-block + .btn-sm-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-md-block {
    display: block;
    width: 100%;
  }
  input[type='submit'].btn-md-block,
  input[type='reset'].btn-md-block,
  input[type='button'].btn-md-block {
    width: 100%;
  }
  .btn-block + .btn-md-block,
  .btn-md-block + .btn-block,
  .btn-md-block + .btn-md-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .btn-lg-block {
    display: block;
    width: 100%;
  }
  input[type='submit'].btn-lg-block,
  input[type='reset'].btn-lg-block,
  input[type='button'].btn-lg-block {
    width: 100%;
  }
  .btn-block + .btn-lg-block,
  .btn-lg-block + .btn-block,
  .btn-lg-block + .btn-lg-block {
    margin-top: 0.5rem;
  }
}

/* Legacy Styles ****************/
.dropdown-menu > li > a {
  font-weight: 500;
  color: #3d4042;
}

@media (max-width: 767px) {
  .navbar-right {
    display: none;
  }
  .navbar-left .dropdown {
    display: none;
  }
}

/* Slide and push menu styles */
ul.slideover {
  margin-left: 60px;
}

ul.slideback {
  margin-left: 0px;
}

.et-slider {
  background-color: #ffffff;
  position: fixed;
  z-index: 1050;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.et-vertical {
  top: 0;
  margin-top: 0px;
  z-index: 1050;
}

.et-left-menu {
  height: 100%;
  width: 60px;
  text-align: center;
  -webkit-box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.et-left-menu a {
  display: block;
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  color: #9a9a9a;
  line-height: 60px;
  text-decoration: none;
  opacity: 0.7;
}

.et-left-menu a:link {
  color: #9a9a9a;
  background-color: #ffffff;
  text-decoration: none;
}

.et-left-menu a:visited {
  color: #9a9a9a;
  background-color: #ffffff;
  text-decoration: none;
}

.et-left-menu a:hover {
  color: #ffffff;
  background-color: #98beff;
  text-decoration: none;
  opacity: 1;
}

.et-left-menu a.active {
  color: #ffffff;
  background-color: #98beff;
  text-decoration: none;
  opacity: 1;
}

.et-right-drawer {
  height: calc(100% - 40px);
  top: 40px;
  width: 240px;
  -webkit-box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.et-left-menu.et-slider-open {
  left: 0px;
}

.et-right-drawer.et-slider-open {
  right: 0px;
}

.et-left-menu.et-slider-closed {
  left: -60px;
}

.et-right-drawer.et-slider-closed {
  right: -240px;
}

#et-nav {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Line styles */
hr.topline {
  border-top: 1px solid #d1d1d1;
  margin-top: -10px;
  margin-bottom: 20px;
}

/* Link styles */
.toolbox {
  vertical-align: middle;
  line-height: 16px;
}

.toolbox a,
.toolbox a:link,
.toolbox a:visited {
  color: #9a9a9a;
  opacity: 0.3;
  text-decoration: none;
}

.toolbox a:active,
.toolbox a:hover {
  opacity: 1;
}

/* Form control overrides */
label.checkbox,
label.radio {
  font-weight: 400;
}

.checkbox-inline,
.radio-inline {
  margin-top: 0px;
  padding-top: 0px;
}

textarea.notesbox {
  min-height: 90px;
  resize: none;
}

/* Backgrounds */
.bg-primary {
  background-color: #98beff !important;
}

.bg-success {
  background-color: #96f066 !important;
}

.bg-warning {
  background-color: #c5b7fb !important;
}

.bg-danger {
  background-color: #ff5757 !important;
}

.bg-info {
  background-color: #c5b7fb !important;
}

.bg-accent {
  background-color: #d1d1d1;
}

.bg-accent2 {
  background-color: #f8f8f8;
}

.bg-white {
  background-color: #ffffff;
}

/* Icon styles */
.et-icons40 {
  font-size: 40px;
  line-height: 40px;
}

.et-icons30 {
  font-size: 30px;
  line-height: 30px;
}

.et-icons30-menu {
  font-size: 30px;
  line-height: 70px;
}

.et-icons24 {
  font-size: 24px;
  line-height: 24px;
}

.et-icons20 {
  font-size: 20px;
  line-height: 20px;
}

.et-icons16 {
  font-size: 16px;
  line-height: 16px;
}

.et-icons12 {
  font-size: 12px;
  line-height: 12px;
}

/* Navbar styles */
.navbar {
  border: none;
  max-height: 40px;
}

.navbar-inverse .divider,
.navbar-inverse .divider-dotted {
  border-right: 1px solid #9a9a9a;
}

.navbar-inverse .divider2 {
  border-left: 1px solid #9a9a9a;
}

.navbar-nav > li > a {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 40px;
  height: 40px;
  margin: 0px;
}

.navbar-nav .et-icons24::before,
.navbar-nav .et-icons16::before {
  line-height: 36px;
}

.navbar-left .dropdown-toggle {
  text-transform: uppercase;
  font-weight: 500;
}

.navbar-nav .dropdown-header {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
}

.navbar-inverse .user-image {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.navbar-inverse img {
  max-height: 30px;
  max-width: 30px;
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.remember-smile-box {
  height: 40px;
  background-color: #4d73ff;
}

/* Modals */
/*.modal-scroll {
	max-height: 90vh;
	.modal-body {
			overflow-y: scroll;
			height: 70vh;
			overflow-x: hidden;
	}
}*/
.modal-content {
  border: 0;
}

.modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 65vh;
  min-height: 210px;
  height: auto;
  margin: 15px 0 0 0;
  padding: 0;
}

.modal-header {
  padding: 2%;
  border-bottom: 1px solid #d6d6d6;
}

.modal-sm .modal-body {
  min-height: auto;
}

.modal-lg .modal-body {
  min-height: 310px;
}

.modal-lg .modal-content {
  max-height: none;
}

.transaction .modal-body,
.activity-window .modal-body,
.edit-lease-data .modal-body,
.edit-move-in-date .modal-body,
.edit-move-date .modal-body {
  min-height: 280px;
}

.modal-sm .modal-header {
  padding: 3%;
  border-bottom: 1px solid #d6d6d6;
}
.modal-sm .modal-header .close {
  margin-top: 8px;
  padding-right: 10px;
}

.modal-header i {
  margin-right: 1%;
  margin-left: 1%;
  color: #4d73ff;
  font-size: 22px;
}

.modal-header h1 {
  margin-bottom: 0;
  display: inline-block;
}

.modal-subheader {
  min-height: 65px;
  margin-bottom: 3px;
  padding: 1.5% 3% 0;
  position: relative;
  top: -15px;
  background: #f1f1f1;
}

.modal-subheader h1 {
  display: inline-block;
  vertical-align: middle;
}

.modal-body h3 {
  margin-top: 2%;
  margin-bottom: 5px;
}

.modal-subheader h4 {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
}

.modal .box-calendar {
  height: 300px;
}

.modal-fixed-position .rdtPicker {
  position: fixed;
}

@media (min-width: 1024px) {
  .modal-subheader h4 {
    width: 67%;
  }
}

@media (min-width: 1280px) {
  .modal-subheader h4 {
    width: 72%;
  }
}

.modal-subheader h5 {
  display: inline-block;
  margin-top: 4px;
  margin-left: 1%;
  vertical-align: middle;
  color: #4d73ff;
}

.modal-subheader .btn-tertiary {
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: 500;
}

.modal-footer {
  padding: 10px;
  border-top: 1px solid #d6d6d6;
}

.modal-footer .btn {
  width: auto;
  min-width: 100%;
  height: 40px;
  float: unset;
  /*padding-top: 9px;*/
}

.modal-footer.modal-footer--threechoices {
  padding-left: 1%;
}

.modal-body p {
  margin: 10px 0;
}

.modal-body p.no-margin-top {
  margin-top: 0;
}

.modal-body > .row {
  padding: 4px 25px 15px;
}

.modal-body > form {
  padding: 20px 20px 0;
}

.modal-sm .form-group {
  margin-bottom: 15px;
}

.modal-lg .modal-footer .btn {
  width: auto;
}

/* Links in modal */
.modal-lg a:hover,
.modal-lg a span:hover {
  text-decoration: none;
}

.modal-lg a i {
  vertical-align: middle;
}

.modal-lg a span {
  text-decoration: underline;
}

a.btn-tertiary span {
  text-decoration: none;
}

@media (min-width: 1024px) {
  .modal-footer .btn {
    min-width: 120px;
  }
  .modal-lg .modal-footer .btn {
    min-width: 180px;
  }
}

.modal-sm .modal-subheader {
  padding-left: 20px;
}

.modal-sm textarea {
  height: 129px;
  box-shadow: none;
  border: 1px solid #d1d1d1;
}

.modal-lg textarea {
  height: 150px;
  margin-bottom: 20px;
  box-shadow: none;
  border: 1px solid #d1d1d1;
}

@media (min-width: 768px) {
  .modal-sm {
    width: 480px;
  }
  .modal-sm.modal-md {
    width: 580px;
  }
  .modal-sm.modal-md textarea {
    height: 80px;
  }
  .modal-footer {
    padding-left: 36px;
    padding-right: 36px;
  }
  .modal-lg .modal-dialog {
    width: 70%;
    max-width: 900px;
  }
}

.modal-confirm {
  width: 100%;
  padding-top: 10%;
  position: absolute;
  background-color: #f8f8f8;
  z-index: 200;
  min-height: 100%;
  margin-top: -15px;
}
.modal-confirm h1,
.modal-confirm p {
  text-align: center;
}
.modal-confirm .btn {
  width: auto;
  min-width: 100%;
  height: 40px;
  padding-top: 9px;
}
@media only screen and (min-width: 768px) {
  .modal-confirm .btn {
    min-width: 180px;
  }
}

.edit-lease-data .modal-confirm,
.edit-move-in-date .modal-confirm,
.edit-move-date .modal-confirm {
  position: absolute;
  top: 0;
}

.edit-lease-data .modal-confirm {
  text-align: center;
  padding: 10px;
  margin-top: 0px;
}

.modal-alert h2 {
  margin: 5% 0 0;
}

.modal-alert .modal-footer {
  border-top: 0;
}

.modal-footer.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* CSS fix for small confirmation modals*/
.modal-footer .btn-toolbar button {
  width: 44%;
  min-width: 1px;
  margin-right: 3%;
  margin-left: 4%;
  float: left;
}

.modal-footer .btn-toolbar .col-xs-6 button {
  width: 100%;
}

.list-select {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.list-select li {
  border-bottom: 1px solid #d1d1d1;
}
.list-select .radio {
  margin-left: 20px;
}
.list-select li:last-child {
  border-bottom: none;
}

.modal-confirm.modal-confirm__withinput {
  padding-top: 2%;
}

/* Dropdown Styles */
.dropdown-menu h5 {
  margin: 10px 0 5px 16px;
  text-transform: uppercase;
}

.dropdown-menu li {
  font-size: 13px;
}

.dropdown-menu li.selected {
  position: relative;
}

.dropdown-menu li.selected i {
  position: absolute;
  top: 5px;
  right: 8%;
  color: #00acc1;
}

.dropdown-menu li.selected:hover i,
.dropdown-menu li.selected:focus i {
  color: white;
}

.dropdown.open .caret {
  transform: rotate(180deg);
}

/* Accordion Styles */
label.accordion-trigger {
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 2%;
  border-top: 1px solid lightgray;
  color: #4d73ff;
  text-transform: uppercase;
  font-weight: 400;
}

label.accordion-trigger:nth-child(0) {
  border-top: 0;
}

.accordion-trigger:after {
  content: '\002b';
  position: absolute;
  top: 6px;
  right: 2%;
  font-size: 22px;
  color: gray;
}

.accordion-click {
  visibility: hidden;
  position: absolute;
  top: 10px;
}

.accordion-click:checked ~ label {
  border-bottom: 0;
}

.accordion-click:checked ~ label + .form-section {
  display: block;
}

.small-input--floating {
  position: absolute;
  top: 4px;
  width: 80px;
  height: 30px;
  line-height: 2em;
  right: 12px;
}

/* Drawer Styles */
.drawer {
  width: 0;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 20px;
  background-color: white;
  border-left: 1px solid #e8e7e7;
  opacity: 0;
  transition: all 0.5s;
  padding: 20px;
  padding-top: 56px;
  visibility: hidden;
}

.drawer.drawer-is-open {
  width: 295px;
  right: 0;
  opacity: 1;
  visibility: visible;
}

.drawer .closebtn {
  position: absolute;
  top: 56px;
  right: 20px;
}

.drawer-actions {
  width: 100%;
  display: block;
  bottom: 0;
  background-color: white;
}
.drawer-actions .btn {
  width: auto;
  min-width: 1px;
}

.drawer-actions .btn-default {
  margin-right: 10px;
}

/* Pagination */
.results-pagination {
  width: 20%;
  vertical-align: top;
  margin-top: 25px;
  margin-right: 15px;
}

@media (min-width: 500px) {
  .results-pagination {
    width: auto;
    margin-top: 28px;
  }
}

/* Search */
.search-input {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 4px;
}
@media (min-width: 768px) {
  .search-input {
    max-width: 350px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }
}

_::-webkit-full-page-media,
_:future,
:root .search-input .input-lg,
:root .search-input .input-md {
  line-height: 19px;
}

.search-input button,
.search-input .input-lg,
.search-input .input-md {
  background-color: white;
  border: 1px solid #b4b4b4;
  box-shadow: none;
}

.search-input .input-lg,
.search-input .input-md {
  width: 100%;
  font-weight: 400;
  outline: none;
}
@media (min-width: 768px) {
  .search-input .input-lg,
  .search-input .input-md {
    padding: 8px;
    position: relative;
    vertical-align: top;
    border-left: 1px solid lightgray;
    font-size: 14px;
  }
}

.search-input button {
  width: 40px;
  height: 40px;
  position: absolute;
  display: inline-block;
  vertical-align: top;
  border: 0;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
  border-top: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
}
.search-input button i {
  color: #9a9a9a;
  font-size: 20px;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .search-actions {
    width: 60%;
    position: absolute;
    right: 176px;
    text-align: right;
    padding-top: 10px;
    margin-right: 12px;
  }
}

.search-actions + button.btn.btn-shout {
  margin: 10px 4px;
}

@media (min-width: 768px) {
  .search-actions-small {
    right: 176px;
    text-align: right;
    padding-top: 10px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}

@media (min-width: 768px) {
  .search-actions.search-align-right {
    position: static;
    float: right;
    max-width: 350px;
  }
}

.react-select__menu {
  z-index: 1000 !important;
}

.search-actions.searchbox-default {
  width: auto;
  position: relative;
  left: 0;
  right: 0;
  padding: 0;
  text-align: left;
}
.search-actions.searchbox-default .search-input {
  width: 100%;
  max-width: none;
  text-align: left;
}
.search-actions.searchbox-default .input-lg,
.search-actions.searchbox-default .input-md {
  width: 100%;
  border: 1px solid #d1d1d1;
}
.search-actions.searchbox-default .search-input button {
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  border-top: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  background-color: #fff;
}

/* Filter Actions */
.filter-actions {
  margin-top: 2px;
}

.filter-actions label {
  margin-right: 2%;
  display: inline-block;
}

.nav-filters {
  height: 85%;
  overflow: auto;
}

/* OFFCANVAS - Main Navigations*/
.navbar {
  background-color: #203089;
  border-radius: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
@media (max-width: 767px) {
  .navbar {
    display: none;
  }
}
.navbar .dropdown-toggle {
  border: none;
  border-radius: 0;
}

.mainbody {
  padding: 40px 0 0 0;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100vh;
}

@media (min-width: 768px) {
  .mainbody.collapsed {
    width: calc(100% - 65px);
    right: 0;
  }
  .mainbody.extended {
    width: calc(100% - 240px);
    right: 0;
  }
}

.flexwrapper {
  /*
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	*/
  height: 100vh;
  width: 100%;
}

#exp-col-button:hover,
#exp-col-button:focus {
  text-decoration: none;
  outline-offset: none;
}

nav.navbar.navbar-inverse.navbar-fixed-top {
  display: block;
  width: 100%;
}

nav.menu-offcanvas .collapse-button {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 1.3em;
  padding-left: 14px;
  height: 42px;
  padding-top: 11px;
  border-top: solid 1px #d6d6d6;
  background-color: #fff;
}
nav.menu-offcanvas .collapse-button a {
  padding: 0;
}
@media (max-width: 767px) {
  nav.menu-offcanvas .collapse-button {
    display: none;
  }
}

nav.menu-offcanvas.extended .collapse-button {
  width: 240px;
}

nav.menu-offcanvas.collapsed .collapse-button {
  width: 65px;
}

.nav-sidenav ul {
  list-style: none;
  padding: 0px;
}

.nav-sidenav > li.first {
  border-left: 5px solid #a58fc4;
}

.nav-sidenav > li.second {
  border-left: 5px solid #58c5c7;
}

.nav-sidenav > li.third {
  border-left: 5px solid #915f13;
}

.nav-sidenav > li.fourth {
  border-left: 5px solid #b6cf2f;
}

.nav-sidenav > li.fifth {
  border-left: 5px solid #fdb731;
}

.nav-sidenav > li.sixth {
  border-left: 5px solid #00aef0;
}

.nav-sidenav li.divider,
.nav-sidenav li.divider-dotted {
  border-bottom: 1px solid #e6e6e6;
}

.nav-sidenav > li {
  margin: 1px 0px;
}

.nav-sidenav > li.sidenav-active-background {
  background-color: #e6e6e6;
}

.nav-sidenav li a {
  color: #4d4d4f;
  display: block;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  padding: 14px 20px;
}

.nav-sidenav li a:hover,
.nav-sidenav li a:focus {
  color: #fff;
}

.nav-sidenav li li a {
  padding: 12px 0;
}

.nav-sidenav li li li a {
  padding: 5px 35px;
}

.nav-sidenav li a:hover {
  background-color: #d0d0d0;
}

.nav-sidenav li a:focus,
.nav-sidenav li.sidenav-active-background.active {
  background-color: #4d73ff;
  color: #fff;
}

.nav-sidenav li.sidenav-active-background.active ul {
  background-color: #fff;
  border: solid 1px #e2e2e2;
  height: auto;
}

nav.menu-offcanvas.collapsed ul.nav-sidenav li ul {
  position: absolute;
  z-index: 99;
  left: 65px;
  top: -1px;
  width: 225px;
  height: auto;
  background-color: #fff;
}

@media (max-width: 767px) {
  nav.menu-offcanvas.collapsed ul.nav-sidenav li ul {
    background: #3594a7;
    position: relative;
    min-width: 100%;
    width: 100%;
    left: 0;
    border: 0;
    box-shadow: none;
  }
  nav.menu-offcanvas.collapsed ul.nav-sidenav li ul a {
    padding-left: 28px;
    display: block;
  }
}

.dropdown-menu {
  right: 0;
  left: auto;
}
@media (min-width: 768px) {
  .dropdown-menu {
    top: 1px;
  }
}
@media (min-width: 992px) {
  .dropdown-menu {
    top: 25px;
  }
}

.dropdown-menu .dropdown-item {
  display: block;
  padding: 5px 10px;
  width: 320px;
}

.btn#dropdown-properties {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 8px 10px;
  text-align: left;
  width: 420px;
  border-right: solid 1px #8a8a8a;
  background-color: transparent;
}

.menu-properties {
  background-color: #fff;
  border: 0;
  font-size: 1.2em;
  border-radius: 0;
  margin-top: 2px;
  padding-bottom: 0;
  width: 430px;
  left: -10px;
}
.menu-properties .lnk-summary {
  display: block;
  font-size: 15px;
  padding: 11px 24px;
  color: #000;
}
.menu-properties .lnk-summary i.icon {
  color: #4d73ff;
}
.menu-properties .lnk-summary:hover {
  text-decoration: none;
}

.menu-properties.dropdown-menu > li > a {
  color: #fff;
  font-size: 16px;
  padding: 7px 10px;
}

.menu-useroptions {
  left: auto;
  right: 0;
  border-radius: 0;
  top: 38px;
  width: 300px;
}
.menu-useroptions .dropdown-menu > li > a {
  font-weight: 500;
  color: #4d73ff;
  font-size: 14px;
}
.menu-useroptions li {
  font-size: 16px;
}
@media (max-width: 767px) {
  .menu-useroptions {
    position: fixed;
    top: 30px;
    height: 100vh;
    width: 100%;
    left: 0 !important;
    display: block;
  }
}

.btn#dropdown-properties .caret {
  float: right;
  margin-right: 19px;
  margin-top: 8px;
}

.dropdown.dropdown-properties-button {
  width: 100%;
  max-width: 420px;
}

.top-right-admin-bar {
  position: relative;
  right: -10px;
}
.top-right-admin-bar .dropdown-item {
  color: #6a6a6a;
}
.top-right-admin-bar .dropdown-item i {
  color: #4d73ff;
}
.top-right-admin-bar .dropdown-menu {
  top: 40px;
}
.top-right-admin-bar #help-section {
  font-size: 17px;
  display: none;
}
.top-right-admin-bar #help-section > span.caret {
  display: none;
}
@media only screen and (min-width: 1780px) {
  .top-right-admin-bar {
    margin-right: -40px;
  }
}

.btn#dropdown-usermenu {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 8px 20px;
  width: 100%;
  text-align: right;
  max-width: 420px;
  background-color: transparent;
  top: 0;
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

.btn#dropdown-usermenu .caret {
  float: right;
  margin-left: 19px;
  margin-top: 8px;
}

.user-picture img {
  width: 20px;
  margin-right: 9px;
}

h1.epm-logo {
  background-image: url(../img/epm-logo-full.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

h1.fortress-logo-gray {
  background-image: url(../img/Fortress-Logo-Gray.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* Mobile Menu */
.mobile-topbar {
  position: fixed;
  display: block;
  width: 100%;
  height: 46px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.381001);
}
.mobile-topbar h1.epm-logo-mobile {
  background-image: url(../img/epm-logo-small.svg);
  display: block;
  height: 40px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 3px auto;
}
.mobile-topbar h1.epm-logo-mobile span {
  display: none;
}
.mobile-topbar .mob-nav-btn {
  margin: 0;
  padding: 0;
  font-size: 1.6em;
}
.mobile-topbar .mob-nav-btn a {
  color: #616161;
  display: block;
  padding: 7px 11px;
}
.mobile-topbar .mob-nav-btn a:focus,
.mobile-topbar .mob-nav-btn a:hover {
  text-decoration: none;
}

.sidemenu-container {
  width: 100%;
  position: absolute;
  top: 0px;
  padding-top: 135px;
  padding-bottom: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .sidemenu-container {
    font-size: 17px;
  }
}

nav.menu-offcanvas {
  background-color: rgba(255, 255, 255, 0.48);
  padding-top: 50px;
  position: absolute;
  height: 100vh;
  padding-bottom: 50px;
  background-color: #fff;
  z-index: 10000;
}
@media (max-width: 767px) {
  nav.menu-offcanvas {
    height: auto;
    min-height: 96vh;
    width: 5px;
    background: transparent;
  }
}
nav.menu-offcanvas a {
  color: #fff;
}
nav.menu-offcanvas ul {
  position: absolute;
  width: 330px;
  height: 100vh;
  left: -1px;
  top: 46px;
  background: #6fb9cc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .menu-offcanvas h1.epm-logo {
    display: none;
  }
}

/* Desktop Menu */
@media (min-width: 768px) {
  nav.menu-offcanvas {
    background-color: #fff;
    min-width: auto;
    padding-top: 5px;
    position: absolute;
    left: 0;
  }
  nav.menu-offcanvas a {
    color: #333;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  nav.menu-offcanvas a {
    padding: 12px 20px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  nav.menu-offcanvas a {
    padding: 18px 20px;
  }
}

@media (min-width: 768px) {
  nav.menu-offcanvas ul {
    position: relative;
    width: auto;
    height: auto;
    left: 0;
    top: 0;
    background: transparent;
    box-shadow: none;
  }
  nav.menu-offcanvas.extended {
    width: 5%;
    min-width: 240px;
    height: auto;
    min-height: 100vh;
  }
  nav.menu-offcanvas.extended ul.nav-sidenav a {
    text-align: left;
  }
  nav.menu-offcanvas.collapsed {
    min-width: 65px;
    /*display: flex;
		flex: 0 0 68px;
		flex-flow: column nowrap;
		justify-content: flex-start;*/
    z-index: 30;
  }
  .mobile-topbar {
    display: none;
  }
  .menu-offcanvas.extended h1.epm-logo {
    width: 108px;
    height: 110px;
    margin: 20px auto;
  }
  .menu-offcanvas.extended h1.fortress-logo-gray {
    width: 180px;
    height: 80px;
    margin: 0 auto;
  }
  .menu-offcanvas.collapsed h1.epm-logo {
    background-image: url(../img/epm-logo-small.svg);
    width: 50px;
    height: 50px;
    margin: 20px auto;
  }
  .menu-offcanvas.collapsed h1.fortress-logo-gray {
    background-image: url(../img/Fortress-Mark-Gray.svg);
    width: 50px;
    height: 50px;
    margin: 20px auto;
  }
  .menu-offcanvas.extended h1.epm-logo a {
    display: block;
    min-height: 100px;
    width: 100%;
  }
  .menu-offcanvas.collapsed h1.epm-logo a {
    display: block;
    min-height: 7px;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .menu-offcanvas ul.nav li i.icon {
    font-size: 19px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .menu-offcanvas ul.nav li i.icon {
    font-size: 22px;
    margin: 0 22px 0 0;
  }
}

@media (min-width: 768px) {
  .menu-offcanvas.extended ul.nav li a span.option-name {
    display: inline-block;
    position: relative;
    top: -5px;
    left: 15px;
  }
  .menu-offcanvas.collapsed ul.nav li a span.option-name {
    display: none;
  }
  .menu-offcanvas h1.epm-logo a span {
    display: none;
  }
  .menu-offcanvas.extended ul.nav li.dropdown.open,
  .menu-offcanvas.extended ul.nav li.dropdown.open > a {
    background-color: #6fb9cc;
    color: #fff;
  }
  .menu-offcanvas.extended ul.nav li.dropdown.open ul {
    background-color: #fff;
    border: solid 1px #e2e2e2;
    position: relative;
    float: inherit;
    border-radius: none;
    box-shadow: none;
  }
}

.tab-pane {
  padding: 0px 0;
}

.panel .nav-tabs {
  display: flex;
  justify-content: flex-start;
  flex-direction: grow;
  border-bottom: 0;
}

.panel .nav-tabs > li {
  flex-grow: 1;
  text-align: center;
}

.nav-tabs > li > a {
  color: #ffffff;
  background-color: #4d73ff;
  border-radius: 0;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #98beff;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #4d73ff;
  font-weight: 500;
  background-color: #ffffff;
  border: 0;
}

.tab-content {
  padding: 58px 8px 16px 8px;
}
@media only screen and (min-width: 1480px) {
  .tab-content {
    padding: 58px 8px 16px 8px;
  }
}

.panel-pag-nav {
  text-align: center;
}

@media (max-width: 767px) {
  .btn-toolbar .btn {
    width: 100%;
  }
}

.header-dashboard-user .nav-tabs {
  margin-top: 20px;
  border-bottom: 0;
}
@media (min-width: 992px) {
  .header-dashboard-user .nav-tabs {
    position: absolute;
    bottom: -20px;
    right: 40px;
  }
}
.header-dashboard-user .nav-tabs > li.active > a,
.header-dashboard-user .nav-tabs > li.active > a:hover,
.header-dashboard-user .nav-tabs > li.active > a:focus {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
}
.header-dashboard-user .nav-tabs > li > a {
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
}

.select-search-box {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  border-radius: 8px;
  position: relative;
}
.select-search-box i.icon {
  margin-right: 10px;
  color: #b3cd78;
}
.select-search-box span {
  font-size: 14px;
}
.select-search-box i.icon.et-search.icon {
  position: absolute;
  top: 11px;
  left: 24px;
  color: #ccc;
}

ul.select-search-box__options {
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
}

.select-search-box--multiple {
  border-radius: 0;
}

.select-search-box--input {
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.4);
}

.select-search-box::after {
  font-family: fontawesome;
  content: '\f078';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  z-index: 1;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 98%;
  height: 38px;
  border: solid 1px #ccc;
  background: none;
  outline: none;
  font-size: 15px;
  padding: 0 20px 0 39px;
  margin: 0 1%;
  color: #6b6b6b;
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-family: sans-serif;
  line-height: 50px;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-weight: normal;
  font-size: 13px;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-weight: normal;
  font-size: 13px;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-weight: normal;
  font-size: 13px;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 0;
  overflow: auto;
  border-bottom: 4px solid #cac6c4;
  border-top: 5px solid #e47168;
  box-shadow: 0 9px 25px rgba(0, 0, 0, 0.15);
  z-index: 100;
  min-height: 49px;
  max-height: 258px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  box-shadow: none;
  border: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
}
.select-search-box__option a {
  padding: 10px 20px;
  display: block;
}
.select-search-box__option a:hover {
  text-decoration: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #eaeae5;
}

.select-search-box__option--selected {
  background: #616b74;
  color: #f7f7f1;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #4a5259;
  color: #f7f7f1;
}

nav.menu-admin {
  background-color: #272727;
  height: 100vh;
}
nav.menu-admin a {
  color: #fff;
}
nav.menu-admin .collapse-button {
  border-top: dotted 1px #444;
  background-color: #212121;
}
nav.menu-admin .nav-sidenav li.sidenav-active-background.active ul {
  background-color: #1b1b1b;
  border: 0;
}

.nav-tabs {
  list-style: none;
  border-bottom: solid 2px #e5e9ea;
  width: 100%;
  display: flex;
}
.nav-tabs li {
  float: left;
  flex-grow: 1;
  text-align: center;
}
.nav-tabs li a {
  display: block;
  padding: 1.2em 1em;
  color: #686868;
  background-color: transparent;
  border: 0;
  font-size: 0.98em;
}
.nav-tabs li a:hover {
  background-color: #fff;
  color: #333;
}
.nav-tabs li a:focus {
  background-color: transparent;
}
.nav-tabs li.active {
  border-bottom: solid 2px #4d73ff;
  position: relative;
  top: 1px;
}

.breadcrumbs {
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  width: 101.6%;
  padding: 16px 0;
  min-height: 50px;
  position: relative;
  left: -1%;
  text-transform: uppercase;
}
.breadcrumbs span.i.icon.et-chevron-right {
  color: #000000;
  margin-left: 5px;
}
.breadcrumbs ul {
  padding-left: 20px;
  list-style: none;
}
.breadcrumbs ul li {
  float: left;
  margin-right: 8px;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs i.icon {
  font-size: 0.7em;
  color: #888;
  font-weight: 500;
}

.searchbox__hasautocomplete {
  position: relative;
}
.searchbox__hasautocomplete .search-input__autocomplete {
  width: 100%;
  position: absolute;
  z-index: 5;
}
.searchbox__hasautocomplete ul {
  max-height: 530px;
  padding: 0;
  overflow: auto;
  border: 1px solid #d1d1d1;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
}
.searchbox__hasautocomplete ul li {
  padding: 8px;
  list-style: none;
  background-color: #ffffff;
  border-bottom: 1px solid #d1d1d1;
  font-size: 13px;
  cursor: pointer;
}
.searchbox__hasautocomplete ul li:hover {
  background-color: #ebf5ff;
}

.mainbody .navbar-staging {
  background-color: #98beff;
}

.mainbody .navbar-development {
  background-color: #7734e7;
}

.mainbody .navbar-training {
  background-color: #4cbb17;
}

.mainbody .env-name {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 8px 20px;
}

.login-container .login-env-name {
  text-align: center;
  bottom: 40px;
  position: relative;
}

.login-border-staging {
  border-bottom: 5px solid #98beff;
}

.login-border-development {
  border-bottom: 5px solid #7734e7;
}

.login-border-training {
  border-bottom: 5px solid #4cbb17;
}

table {
  font-size: 13px;
}

table > tbody > tr > td {
  vertical-align: middle !important;
}

table .form-control {
  font-size: 13px;
}

.table-prospects td a:focus {
  text-decoration: none;
}

.table-section .dropdown-menu {
  top: 22px;
  box-shadow: none;
}

/*
.table-prospects ul.dropdown-menu:before {
	content: '';
	width: 0;
	height: 0;
	display: block;
	position: absolute;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #9A9A9A;
	top: -8px;
	left: 8px;
}

.table-prospects ul.dropdown-menu:after{
	content: '';
	width: 0;
	height: 0;
	display: block;
	position: absolute;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid white;
	top: -8px;
	left: 8px;
}*/
.table-scroll {
  max-width: 96%;
  overflow-x: auto;
  border: solid 1px #e4e4e4;
  padding: 0;
  margin: auto;
}
.table-scroll table {
  margin-top: 0;
  margin-bottom: 0;
}

.table-scroll-sm-only {
  max-width: 100%;
  overflow-x: auto;
  border: solid 1px #e4e4e4;
  padding: 0;
  margin: auto;
}
.table-scroll-sm-only table {
  margin-top: 0;
  margin-bottom: 0;
}

.table-units-container {
  max-width: 100%;
  max-height: 73vh;
  min-height: 55px;
}
.table-units-container table td {
  font-size: 0.9em;
  text-align: center;
}
.table-units-container .sort-option {
  margin-left: 1px;
}

.units-specials {
  margin: 0 auto 10px auto;
  width: 99.6%;
}
.units-specials .alert {
  padding: 10px;
  font-size: 0.89em;
}
.units-specials .alert p {
  margin: 0;
  white-space: pre-line;
}
.units-specials .alert .btn {
  margin: 0;
}

/* Styles for tables w mobile accordion */
.table-prospects {
  margin-top: 1%;
}

@media (min-width: 776px) and (max-width: 1024px) {
  .table-prospects td {
    font-size: 12px;
  }
  .table-prospects tbody td a {
    word-break: break-all;
  }
}

a.btn {
  min-height: 18px;
  padding: 5px 15px;
}

.table-section.form-section {
  display: table-row;
}

.table-prospects .collapse {
  display: table-row;
}

.table-prospects .table-header {
  color: white;
  background-color: #4d73ff;
  font-weight: 400;
}
.table-prospects .table-header th {
  background-color: #4d73ff;
  font-weight: 400;
}
.table-prospects .table-header th.header-lighter {
  background-color: #98beff;
}
.table-prospects .table-header th.header-darker {
  background-color: #2136e1;
}
.table-prospects .table-header th.header-darker--var {
  background-color: #2136e1;
}
.table-prospects .table-header th.header-darker--var-b {
  background-color: #2136e1;
}
.table-prospects .table-header th.header-darker--var-c {
  background-color: #2136e1;
}
.table-prospects .table-header th.bg-lighter-gray {
  background-color: #d1d1d1;
  color: black;
}

.table-prospects .table-header a,
.table-prospects .table-header ul a {
  color: white;
}

.table-prospects .table-header ul a {
  color: #6a6a6a;
}

.table-prospects .table-header ul a:hover,
.table-prospects .table-header ul a:focus {
  color: white;
}

.header-lighter {
  background-color: #98beff;
}

.header-darker {
  background-color: #2136e1;
}

.header-darker--var {
  background-color: #2136e1;
}

.header-darker--var-b {
  background-color: #2136e1;
}

table.table-prospects thead > tr:only-child th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-prospects thead > tr:first-child {
  height: 35px;
}

table.table-prospects thead > tr:first-child th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-prospects thead > tr:not(:first-child) th {
  position: sticky;
  position: -webkit-sticky;
  top: 35px;
  z-index: 1;
}

/* Last column size fix */
/*
.table-prospects td:last-child {
	width: 18%;
	min-width: 105px;
}

@include breakpoint(lg) {
		.table-prospects td:last-child {
		width: 12.5%;
	}
}
*/
.table-prospects td.text-center:last-child {
  min-width: 105px;
}

.table-section td > span.row-detail {
  display: none;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: white;
}

@media (max-width: 767px) {
  .table-prospects > tbody > tr:nth-child(6n - 2),
  .table-prospects > tbody > tr:nth-child(6n - 1) {
    background-color: white;
  }
  .table-prospects > tbody > tr:nth-child(6n - 5),
  .table-prospects > tbody > tr:nth-child(6n - 4) {
    background-color: #f8f8f8;
  }
  .table-prospects td:last-child {
    width: 100%;
  }
  .table-section td > span.row-detail {
    display: inline-block;
    margin-right: 10px;
    font-weight: 500;
  }
  th.clickable {
    position: relative;
  }
  th span.accent-open {
    width: 7px;
    height: 7px;
  }
  th.clickable:after {
    width: 10px;
    height: 7px;
    position: absolute;
    right: 4%;
    top: 5%;
    font-size: 22px;
    color: #4d73ff;
    display: block;
  }
  th.clickable.collapsed:after {
    content: '\002b';
  }
  th.clickable.expanded:after {
    content: '\2212';
  }
  .table-prospects .collapse {
    display: none;
  }
  .table-section th {
    font-weight: normal;
  }
  .table-section td,
  .table-section th {
    width: 100%;
    display: block !important;
  }
  .table-section td.hidden-xs {
    display: none !important;
  }
  .table-prospects .table-section td {
    border-top: 0;
  }
}

.widget .table,
.panel .table {
  font-size: 0.98em;
  margin-bottom: 0;
}

.widget .table-header,
.widget .table-active,
.panel .table-header,
.panel .table-active {
  color: white;
  background-color: #4d73ff;
  border-radius: 40px 10px;
}

.widget .table-results,
.panel .table-results {
  color: white;
  background-color: #4d73ff;
  text-align: center;
}

.widget .table-titles,
.panel .table-titles {
  font-weight: 500;
}

.widget .table-footer,
.panel .table-footer {
  background-color: #ebf5ff;
  color: #4d73ff;
}

.table-simple.table > tbody > tr > th,
.table-simple.table > tbody > tr > td {
  padding: 5px 15px;
  border: 0;
}

.sort-option {
  cursor: pointer;
  margin-left: 5px;
}

.sort-option.et-sortable {
  opacity: 0.65;
}

.scrollable {
  max-width: 100%;
  overflow: auto;
}
.scrollable table {
  margin: 5px;
}

.table-prospects th,
.table-prospects tbody,
.table > thead > tr > td {
  text-align: center;
}

.container-scrollable,
.form-groupbox--scrollable {
  overflow: auto;
  max-height: 40vh;
}
@media (min-width: 1200px) {
  .container-scrollable,
  .form-groupbox--scrollable {
    max-height: 55vh;
  }
}
.container-scrollable::-webkit-scrollbar-track,
.form-groupbox--scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f8f8f8;
}
.container-scrollable::-webkit-scrollbar,
.form-groupbox--scrollable::-webkit-scrollbar {
  width: 8px;
  background-color: #f8f8f8;
}
.container-scrollable::-webkit-scrollbar-thumb,
.form-groupbox--scrollable::-webkit-scrollbar-thumb {
  background-color: #98beff;
}
.container-scrollable .table-prospects,
.form-groupbox--scrollable .table-prospects {
  margin-top: 0;
}

.table-footer {
  padding: 10px;
  background-color: #ebf5ff;
  font-size: 14px;
  position: relative;
}
.table-footer .table-total {
  margin-right: 20px;
  font-weight: 500;
  color: #4d73ff;
}

tfoot.table-footer {
  padding: 10px;
  background-color: #ebf5ff;
  font-size: 14px;
  position: relative;
  font-weight: 500;
  color: #4d73ff;
}

.table tbody i.et-alert-urgent.text-orange,
.table tbody i.et-alert-urgent.text-red {
  font-size: 18px;
  font-weight: 500;
}

.table tbody i.et-isolated-check {
  font-size: 18px;
}

.table-units-container {
  max-width: 100%;
}
.table-units-container table td {
  font-size: 0.9em;
}

.panel .table-units-container {
  max-width: 98%;
}

.legend-simple p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
}

tr.highlight {
  background-color: #fff0f0 !important;
}

tr.rowYellow {
  background-color: #fbe8ca !important;
}

tr.rowGreen {
  background-color: #f1fee7 !important;
}

@media (min-width: 1500px) {
  .table-options {
    margin-right: 20px;
    float: right;
  }
}

.table-deposits-container,
.table-scrollable-container {
  max-height: 70vh;
  max-width: 100%;
  padding: 0;
}

.table-scroll .table > thead > tr > th {
  border-top: 0;
  border-bottom: 0;
}

td.text-red a {
  color: #d70000;
}

td.text-red a:hover,
td.text-red a:visited,
td.text-red a:focus {
  color: #b10303;
}

.centered-vertical {
  display: table-cell;
  vertical-align: middle !important;
}

.dropdown.btn-download-options button#download-options-menu.btn-default:focus {
  color: inherit;
}

.dropdown-menu#download-options {
  top: 40px;
}

/* States **********************/
