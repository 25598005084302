@charset "UTF-8";
@media print {
  html,
  body,
  .mainbody,
  .collapsed,
  .container-fluid,
  .form-wrapper {
    min-width: 1100px !important;
    height: fit-content !important;
    width: fit-content !important;
    overflow-x: hidden;
  }
  h3 {
    font-size: 0.89em;
    font-weight: 500;
  }
  .hidden {
    display: none;
    visibility: hidden;
  }
  .visible-phone {
    display: none !important;
  }
  .visible-tablet {
    display: none !important;
  }
  .hidden-desktop {
    display: none !important;
  }
  .visible-desktop {
    display: inherit !important;
  }
  .no-print {
    display: none;
  }
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* Lease app */
  input,
  select {
    border: 0 !important;
    border-bottom: solid 1px #000 !important;
    border-radius: 0 !important;
    color: #fff;
  }
  select {
    color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
  select option {
    color: #fff;
  }
  textarea {
    border: dotted 1px #000 !important;
    page-break-inside: avoid;
    border-radius: 0 !important;
  }
  hr.breakpage {
    page-break-after: always;
    visibility: hidden;
  }
  .input-group-addon {
    border: 0;
    border-bottom: solid 1px #000;
    border-radius: 0;
  }
  .guidelines-container {
    overflow-y: visible;
    height: auto;
    border: 0;
    padding: 0;
  }
  .guidelines-container .subheading {
    border-bottom: dotted 1px #666;
    margin-bottom: 24px;
    page-break-after: avoid;
  }
  .mastertitle {
    padding: 0;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  .tag-required {
    margin-left: 16px;
  }
  .alert-primary,
  .form-groupbox {
    background-color: #8e8e8e;
    border: 1px dotted #979797;
  }
  .form-groupbox.form-groupbox--flexible {
    border: 1px solid black;
  }
  .section-leaseappform.form-wrapper {
    padding: 34pt 30pt;
    margin: 0;
    width: 92%;
  }
  input::placeholder,
  textarea::placeholder {
    visibility: hidden;
  }
  .section-leaseappform .form-content {
    padding: 0;
    margin: 0 1%;
  }
  .signature-container input[type='text'] {
    border-bottom: dotted 1px #000 !important;
  }
  .print-only {
    display: block;
  }
  select {
    font-size: 0% !important;
  }
  select[data-default-value-selected='true'] {
    font-size: 14px !important;
  }
  select[data-default-value-selected='true'] option {
    font-size: 12px !important;
  }
  /* Printable Modals */
  .modal,
  .fade.modal.in {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .modal .container-fluid {
    margin: 0;
  }
  .modal-sm.modal-dialog {
    width: 100%;
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 100%;
  }
  ​ .modal-content {
    border: none !important;
    max-height: none !important;
  }
  .modal-header {
    padding: 0 !important;
    border: none !important;
  }
  ​ .modal-body,
  .transaction .modal-body {
    min-height: 1px !important;
    max-height: fit-content !important;
    overflow: visible !important;
  }
  ​ body.modal-open {
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
  }
  .modal button.close {
    display: none;
    visibility: hidden;
  }
  .modal .form-group {
    margin-bottom: 10px;
  }
  .modal-backdrop {
    visibility: hidden;
    display: none;
  }
  /* General */
  .breadcrumbs,
  .mobile-topbar,
  .menu-offcanvas {
    display: none;
    visibility: hidden;
  }
  div.highlighted-footer {
    background: #d5d5d5 !important;
    border: 1px dotted #666;
  }
  button,
  .btn,
  tbody td .btn.btn-sm.btn-default {
    display: none;
  }
  .table thead.table-header--printable th,
  .table thead.table-header.table-header--printable {
    background: #000 !important;
    background-color: #000 !important;
    color: #fff !important;
  }
  .mainbody {
    padding-top: 0;
    margin-top: 0;
  }
  .block-with-padding,
  .bodywrap,
  .container-fluid {
    padding: 0 !important;
    margin: 0 !important;
  }
  .custom-checkbox input:checked ~ .custom-check-square:after {
    display: block !important;
    visibility: visible;
  }
  .custom-checkbox .custom-check-square:after {
    content: '\ea00';
    font-family: 'eticons';
    top: -2px;
    left: 0;
    font-weight: 500;
  }
  .form-footer .custom-check-square,
  .form-footer span.custom-check-square {
    display: none;
  }
  .box-dotted {
    border: dotted 1px;
    min-height: 30px;
  }
}
